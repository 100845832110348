import React from "react";

const Footer = () => {
  
  return (
    <>
      <footer
        className="footer-bg footer-p pt-50"
        style={{
          background: `url(${require("../images/f-bg.png")})`,
          backgroundSize: "cover",
        }}
      >
        <div className="footer-top-heiding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="section-title">
                  <h3 className="text-white">
                    {" "}
                    Доверие и защита в каждом полисе.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="flog mb-25">
                    <a href="#">
                      <img src={require("../images/logo.png")} alt="logo" />
                    </a>
                  </div>
                  <p>
                    Ваша надежная защита на дороге. Госавтополис – заботится о
                    вашей безопасности с любовью, чтобы каждая поездка была
                    спокойной и уверенной.
                  </p>
                  <a href="/osago" className="btn ss-btn ">Расчет ОСАГО <i className="fal fa-arrow-right"></i></a>
                  <div className="f-contact">
                    <ul></ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div>
                  <div className="textwidget">
                    <div className="footer-widget mb-30">
                      <div className="f-widget-title">
                        <h5>
                          <a href="/">Бытрое меню</a>
                        </h5>
                      </div>
                      <div className="footer-link">
                        <ul>
                          <li>
                            <a href="/property">
                              <i className="fas fa-chevron-right"></i>{" "}
                              Страхование имущества
                            </a>
                          </li>
                          <li>
                            <a href="/health">
                              <i className="fas fa-chevron-right"></i>{" "}
                              Страхование здоровья
                            </a>
                          </li>
                          <li>
                            <a href="/tourism">
                              <i className="fas fa-chevron-right"></i>{" "}
                              Туристическое страхование
                            </a>
                          </li>
                          <li>
                            <a href="/hypothec">
                              <i className="fas fa-chevron-right"></i> Ипотека
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-chevron-right"></i> Отличие
                              КАСКО от ОСАГО
                            </a>
                          </li>
                          <li>
                            <a href="/contacts">
                              <i className="fas fa-chevron-right"></i> Контакты
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h5 className="mb-2">Контакты:</h5>
                  </div>
                  <div className="f-contact">
                    <ul>
                      <li>
                        <i className="icon far fa-phone"></i>
                        <span>
                          +7959 5555 474
                          <br />
                          Короткий номер: 474
                        </span>
                      </li>

                      <li>
                        <i className="icon far fa-clock"></i>
                        <span>
                          Будние дни: 09:00 - 16:00
                          <br />
                          Суббота: 09:00 - 14:00
                        </span>
                      </li>

                      <li>
                        <i className="icon dripicons-mail"></i>
                        <span>
                          <a href="mailto:lugansk@gosavtopolis.ru">
                            lugansk@gosavtopolis.ru
                          </a>
                          <br />
                          почта офис
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget ">
                  <div className="f-contact mb-4 pl-4 pl-sm-none">
                    <i className="icon dripicons-location"></i>
                    <span>
                      <a href="#">
                        г. Луганск
                      </a>
                      <br />
                      <a href="#">ул. Ленина 236/30</a>
                    </span>
                  </div>
                  <hr />
                  <div className="book-schedule">
                    <div className="icon">
                      <img src={require('../images/icon/crv-icon1.png')} alt="comment" />
                    </div>
                    <div className="text">
                      <h4>Нужна бесплатная консультация?</h4>
                      <a href="#">Позвоните там!</a>
                    </div>
                  </div>
                  <div className="footer-social mt-4 text-center sm-mb-2">
                    <a href="https://vk.com/osagolnr" target="_blank">
                      <i className="fab fa-vk"></i>
                    </a>
                    <a href="https://t.me/osagolnr_gap" target="_blank">
                      <i class="fab fa-telegram-plane"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div>
                  <div className="textwidget">
                    <ul>
                      <li className="d-sm-block">
                        <a href="#">Политика конфиденциальности</a>
                      </li>
                      <li>
                        <a href="#">Пользовательское соглашение</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 pl-40 pl-sm-15">© 2023. Все права защищены..</div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
