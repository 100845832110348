import { Store } from "react-notifications-component";

export const sendNotification = (type, title, message) => {
  try {
    Store.addNotification({
      title,
      message,
      type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 4000,
        onScreen: true
      },
      width: 300
    });
  } catch (e) {
    console.log(e.message);
  }
};
