import React, {useState} from "react";

const Header = () => {

  const renderActiveClass = (type) => {
    if (window.location.pathname === type) {
      return "active";
    }
  };

  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <header className="header-area">
        <div className="header-top second-header d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-9 col-md-12 d-none  d-md-block">
                <div className="header-cta">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fal fa-map-pin"></i> Луганск, ул. Ленина
                        236/30
                      </a>
                    </li>
                    <li>
                      <a href="tel:+7959 5555 474">
                      <span>
                        <i className="fal fa-phone"></i> +7959 5555 474
                      </span>
                      </a>
                    </li>
                    <li>
                      <a href="tel:474">
                      <span>
                        <i className="fal fa-phone"></i> короткий номер:{" "}
                        <strong>474</strong>
                      </span>
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/osagolnr_gap" target="_blank" className="mr-3"><i class="fab fa-telegram-plane"></i></a>
                      <a href="https://vk.com/osagolnr" target="_blank"><i class="fab fa-vk"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 d-none d-lg-block">
                <div className="header-social text-right">
                  <span>
                    <a href="#" title="">
                      Звонить с 09:00 - 16:00
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="header-sticky" className="menu-area">
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3">
                  <div className="logo">
                    <a href="/">
                      <img
                        style={{verticalAlign: 'baseline'}}
                        src={require("../images/logo_dark.png")}
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9">
                  <div className="main-menu text-right text-xl-right">
                    <nav id="mobile-menu">
                      <ul>
                        <li className={` ${renderActiveClass("#")}`}>
                          <a href="/">Авто</a>
                        </li>
                        <li className={` ${renderActiveClass("/property")}`}>
                          <a href="/property">Имущество</a>
                        </li>
                        <li className={` ${renderActiveClass("/hypothec")}`}>
                          <a href="/hypothec">Ипотека</a>
                        </li>
                        <li className={` ${renderActiveClass("/health")}`}>
                          <a href="/health">Здоровье</a>
                        </li>
                        <li className={` ${renderActiveClass("/tourism")}`}>
                          <a href="/tourism">Туризм</a>
                        </li>
                        <li className="has-sub">
                          <a href="#">Информация</a>
                          <ul>
                            <li>
                              <a href="/info/pravila">Закон и правила ОСАГО</a>
                            </li>
                            <li>
                              <a href="/info/differences">Отличие КАСКО от ОСАГО</a>
                            </li>
                            <li>
                              <a href="/info/reliable">Как проверить страховое агентство</a>
                            </li>
                            <li>
                              <a href="/info/waht_to_do">Что делать при ДТП</a>
                            </li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <a href="#">Расчет</a>
                          <ul>
                            <li>
                              <a href="/osago">Расчет ОСАГО</a>
                            </li>
                          </ul>
                        </li>

                        <li className={` ${renderActiveClass("#")}`}>
                          <a href="/contacts">Контакты</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mobile-menu mean-container d-block d-sm-none">
                    <div className="mean-bar">
                      {showMenu === false ? (
                        <a className="meanmenu-reveal" 
                          style={{ right: 0, left:'auto', marginTop: '1px'}}
                          onClick={() => setShowMenu(true)}
                        >
                          <span></span><span></span><span></span>
                        </a>
                      ) : (
                        <>
                          <a className="meanmenu-reveal meanclose"
                            style={{
                              right: '0px',
                              left: 'auto',
                              textAlign: 'center',
                              textIndent: '0px',
                              fontSize: '18px'
                            }}
                            onClick={() =>setShowMenu(false)}
                          >
                            X
                          </a>
                          <nav className="mean-nav">
                            <ul>
                              <li className={` ${renderActiveClass("#")}`}>
                                <a href="/">Авто</a>
                              </li>
                              <li className={` ${renderActiveClass("/property")}`}>
                                <a href="/property">Имущество</a>
                              </li>
                              <li className={` ${renderActiveClass("/hypothec")}`}>
                                <a href="/hypothec">Ипотека</a>
                              </li>
                              <li className={` ${renderActiveClass("/health")}`}>
                                <a href="/health">Здоровье</a>
                              </li>
                              <li className={` ${renderActiveClass("/tourism")}`}>
                                <a href="/tourism">Туризм</a>
                              </li>
                              <li className="has-sub">
                                <a href="#">Информация</a>
                                <ul>
                                  <li>
                                    <a href="/info/pravila">Закон и правила ОСАГО</a>
                                  </li>
                                  <li>
                                    <a href="/info/differences">Отличие КАСКО от ОСАГО</a>
                                  </li>
                                  <li>
                                    <a href="/info/reliable">Как проверить страховое агентство</a>
                                  </li>
                                  <li>
                                    <a href="/info/waht_to_do">Что делать при ДТП</a>
                                  </li>
                                </ul>
                              </li>
                              <li className="has-sub">
                                <a href="#">Расчет</a>
                                <ul>
                                  <li>
                                    <a href="/osago">Расчет ОСАГО</a>
                                  </li>
                                </ul>
                              </li>
                              <li className={` ${renderActiveClass("#")}`}>
                                <a href="/contacts">Контакты</a>
                              </li>
                            </ul>
                          </nav>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
