import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Scss
import "./scss/style.scss";
// Pages admin
import NoRoute from "./pages/NoRoute";
import Home from "./pages/Home";

import Property from "./pages/Property";
import Hypothec from "./pages/Hypothec";
import Health from "./pages/Health";
import Tourism from "./pages/Tourism";
import Osago from "./pages/Osago";
import PravilaOsago from "./pages/info/PravilaOSAGO";
import Differences from "./pages/info/Differences";
import Reliable from "./pages/info/Reliable";
import WhatToDo from "./pages/info/WhatToDo";

import Contacts from "./pages/Contacts";

export default class App extends React.Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/property" component={Property} />
          <Route exact path="/hypothec" component={Hypothec} />
          <Route exact path="/health" component={Health} />
          <Route exact path="/tourism" component={Tourism} />
          <Route exact path="/contacts" component={Contacts} />
          <Route exact path="/osago" component={Osago} />
          <Route exact path="/info/pravila" component={PravilaOsago} />
          <Route exact path="/info/differences" component={Differences} />
          <Route exact path="/info/reliable" component={Reliable} />
          <Route exact path="/info/waht_to_do" component={WhatToDo} />
          <Route component={NoRoute} />
        </Switch>
      </Router>
    );
  }
}
