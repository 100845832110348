import React from "react";

const Vacancy = () => {

  return (
    <>
      <section id="make-call-area" className="make-call-area p-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="section-title cta-title pt-180 mb-20">
                <h5>
                  <span className="line">
                    {" "}
                    <img
                      src={require("../images/bg/circle_left.png")}
                      alt="circle_right"
                    />
                  </span>{" "}
                  Открытая вакансия
                </h5>
                <h4 className="text-light lh-35">
                  Страховой компании "Госавтополис" в г.Луганск <br />{" "}
                  и по всему ЛНР требуются сотрудники:
                </h4>
                <h2 className="mt-4 text-vacancy">
                  Страховой агент: ЗП от 80 тыс. рублей
                </h2>
              </div>
              <div className="row ">
                <div className="col-md-3 col-sm-12">
                  <p className="lh-30 sm-text-center">
                    <strong>График работы:</strong> <br />
                    пн-пт 09:00 - 16:00 <br />
                    сб - 09:00 - 14:00 <br />
                    вс выходной
                  </p>
                </div>
                <div className="col-md-9 col-sm-12 col-12 sm-text-center">
                  <div className="call-number mt-20 mb-15 sm-text-center">
                    <div className="icon">
                      <img
                        src={require("../images/icon/call-icon.png")}
                        alt="circle_right"
                        width="40px"
                      />
                    </div>
                    <h2>
                      +7959 5555 474 <br />
                      <span>
                        {" "}
                        Короткий номер:{" "}
                        <span className="text-primary">474</span>{" "}
                      </span>{" "}
                      <br />
                      <span className="text-muted">
                        <i>Звонить с 09:00 - 16:00</i>
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 d-block">
              <div className="img">
                <img
                  src={require("../images/chair.png")}
                  alt="features-lg-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Vacancy;