export const Cars = {
  "Acura": {
    "model": {
      "CDX": {
        "year": "2016-"
      },
      "CL": {
        "year": "1998-2003"
      },
      "EL": {
        "year": "1997-2005"
      },
      "ILX": {
        "year": "2012-"
      },
      "Integra": {
        "year": "1995-2006"
      },
      "MDX": {
        "year": "2001-"
      },
      "NSX": {
        "year": "2001-"
      },
      "RDX": {
        "year": "2006-"
      },
      "RL": {
        "year": "1995-2012"
      },
      "RLX": {
        "year": "2013-"
      },
      "RSX": {
        "year": "2002-2006"
      },
      "TL": {
        "year": "1999-2014"
      },
      "TLX": {
        "year": "2014-"
      },
      "TLX-L": {
        "year": "2018-"
      },
      "TSX": {
        "year": "2003-2014"
      },
      "ZDX": {
        "year": "2010-2013"
      }
    }
  },
  "Alfa Romeo": {
    "model": {
      "146": {
        "year": "1994-2001"
      },
      "147": {
        "year": "1998-2010"
      },
      "156": {
        "year": "1997-2007"
      },
      "159": {
        "year": "2005-2011"
      },
      "166": {
        "year": "1998-2007"
      },
      "147 GTA": {
        "year": "2002-2010"
      },
      "156 GTA": {
        "year": "2002-2007"
      },
      "4C": {
        "year": "2013-2020"
      },
      "8C Competizione": {
        "year": "2008-2010"
      },
      "Brera": {
        "year": "2005-2010"
      },
      "Giulia": {
        "year": "2016-"
      },
      "Giulietta": {
        "year": "2010-"
      },
      "GT": {
        "year": "2003-2022"
      },
      "GTV": {
        "year": "1995-2006"
      },
      "MiTo": {
        "year": "2008-2018"
      },
      "Spider": {
        "year": "1998-2010"
      },
      "Stelvio": {
        "year": "2017-"
      },
      "Tonale": {
        "year": "2022-"
      }
    }
  },
  "Aston Martin": {
    "model": {
      "Cygnet": {
        "year": "2011-2013"
      },
      "DB11": {
        "year": "2016-"
      },
      "DB9": {
        "year": "2004-2016"
      },
      "DBS": {
        "year": "2003-2012"
      },
      "DBS Superleggera": {
        "year": "2018-"
      },
      "DBS Violante": {
        "year": "2007-2012"
      },
      "DBX": {
        "year": "2020-"
      },
      "Rapide": {
        "year": "2010-2019"
      },
      "V12 Vanquish": {
        "year": "2001-2008"
      },
      "V12 Vantage": {
        "year": "2010-2018"
      },
      "V8 Vantage": {
        "year": "2005-2007"
      },
      "Valkyrie": {
        "year": "2019-2020"
      },
      "Vanquish": {
        "year": "2011-2018"
      },
      "Vantage": {
        "year": "2018-"
      },
      "Virage": {
        "year": "2011-2012"
      },
      "Zagato Coupe": {
        "year": "2016-2016"
      }
    }
  },
  "Audi": {
    "model": {
      "A1": {
        "year": "2010-"
      },
      "A2": {
        "year": "2000-2006"
      },
      "A3": {
        "year": "1996-"
      },
      "A4": {
        "year": "1995-"
      },
      "A4 Allroad Quattro": {
        "year": "2009-"
      },
      "A5": {
        "year": "2007-"
      },
      "A6": {
        "year": "1998-"
      },
      "A7": {
        "year": "2010-"
      },
      "A8": {
        "year": "1994-"
      },
      "Allroad": {
        "year": "2000-2011"
      },
      "e-tron": {
        "year": "2019-"
      },
      "e-tron GT": {
        "year": "2020-"
      },
      "e-tron Sportback": {
        "year": "2020-"
      },
      "Q2": {
        "year": "2016-"
      },
      "Q3": {
        "year": "2011-"
      },
      "Q3 Sportback": {
        "year": "2019-"
      },
      "Q4": {
        "year": "2021-"
      },
      "Q4 Sportback": {
        "year": "2022-"
      },
      "Q5": {
        "year": "2008-"
      },
      "Q5 Sportback": {
        "year": "2021-"
      },
      "Q7": {
        "year": "2006-"
      },
      "Q8": {
        "year": "2018-"
      },
      "R8": {
        "year": "2006-"
      },
      "RS e-tron GT": {
        "year": "2021-"
      },
      "RS Q3": {
        "year": "2012-"
      },
      "RS Q3 Sportback": {
        "year": "2019-"
      },
      "RS Q7": {
        "year": "0-"
      },
      "RS Q8": {
        "year": "2019-"
      },
      "RS3": {
        "year": "2011-"
      },
      "RS4": {
        "year": "2005-"
      },
      "RS5": {
        "year": "2010-"
      },
      "RS6": {
        "year": "2002-"
      },
      "RS7": {
        "year": "2013-"
      },
      "S1": {
        "year": "2014-2018"
      },
      "S3": {
        "year": "2006-"
      },
      "S4": {
        "year": "1998-"
      },
      "S5": {
        "year": "2007-"
      },
      "S6": {
        "year": "1999-"
      },
      "S7": {
        "year": "2012-"
      },
      "S8": {
        "year": "1996-"
      },
      "SQ2": {
        "year": "2019-"
      },
      "SQ5": {
        "year": "2012-"
      },
      "SQ5 Sportback": {
        "year": "2021-"
      },
      "SQ7": {
        "year": "2016-"
      },
      "SQ8": {
        "year": "2019-"
      },
      "TT": {
        "year": "1998-"
      },
      "TT RS": {
        "year": "2008-"
      },
      "TTS": {
        "year": "2008-"
      }
    }
  },
  "Bentley": {
    "model": {
      "Arnage": {
        "year": "1999-2007"
      },
      "Azure": {
        "year": "1999-2009"
      },
      "Bentayga": {
        "year": "2015-"
      },
      "Brooklands": {
        "year": "2008-2011"
      },
      "Continental": {
        "year": "2003-"
      },
      "Continental Flying Spur": {
        "year": "2005-2013"
      },
      "Continental GT": {
        "year": "2018-"
      },
      "Flying Spur": {
        "year": "2013-2019"
      },
      "Mulsanne": {
        "year": "2010-"
      }
    }
  },
  "BMW": {
    "model": {
      "1 series": {
        "year": "2004-"
      },
      "2 series": {
        "year": "2013-"
      },
      "3 series": {
        "year": "1982-"
      },
      "4 series": {
        "year": "2013-"
      },
      "5 series": {
        "year": "1988-"
      },
      "6 series": {
        "year": "2004-"
      },
      "7 series": {
        "year": "1995-"
      },
      "8 series": {
        "year": "1990-"
      },
      "i3": {
        "year": "2013-"
      },
      "i4": {
        "year": "2021-"
      },
      "i8": {
        "year": "2014-2020"
      },
      "iX": {
        "year": "2021-"
      },
      "iX3": {
        "year": "2020-"
      },
      "M2": {
        "year": "2015-"
      },
      "M3": {
        "year": "1992-"
      },
      "M4": {
        "year": "2012-"
      },
      "M5": {
        "year": "1998-"
      },
      "M6": {
        "year": "2005-2018"
      },
      "M8": {
        "year": "2019-"
      },
      "X1": {
        "year": "2009-"
      },
      "X2": {
        "year": "2017-"
      },
      "X3": {
        "year": "2004-"
      },
      "X3 M": {
        "year": "2019-"
      },
      "X4": {
        "year": "2014-"
      },
      "X4 M": {
        "year": "2019-"
      },
      "X5": {
        "year": "2000-"
      },
      "X5 M": {
        "year": "2009-"
      },
      "X6": {
        "year": "2008-"
      },
      "X6 M": {
        "year": "2009-"
      },
      "X7": {
        "year": "2018-"
      },
      "Z3": {
        "year": "1996-2003"
      },
      "Z4": {
        "year": "2002-"
      },
      "Z8": {
        "year": "2000-2006"
      }
    }
  },
  "Brilliance": {
    "model": {
      "H230": {
        "year": "2012-2019"
      },
      "V3": {
        "year": "2014-"
      },
      "V5": {
        "year": "2011-"
      }
    }
  },
  "Bugatti": {
    "model": {
      "Chiron": {
        "year": "2015-"
      },
      "Divo": {
        "year": "2019-"
      },
      "Veyron": {
        "year": "2005-2015"
      }
    }
  },
  "Buick": {
    "model": {
      "Century": {
        "year": "1997-2005"
      },
      "Enclave": {
        "year": "2007-"
      },
      "Encore": {
        "year": "2020-"
      },
      "Envision": {
        "year": "2014-"
      },
      "GL8 ES": {
        "year": "2021-"
      },
      "La Crosse": {
        "year": "2004-2016"
      },
      "LaCrosse": {
        "year": "2016-"
      },
      "Le Sabre": {
        "year": "1999-2005"
      },
      "Lucerne": {
        "year": "2005-2011"
      },
      "Park Avenue": {
        "year": "1996-2012"
      },
      "Rainier": {
        "year": "2003-2007"
      },
      "Regal": {
        "year": "2009-"
      },
      "Rendezvouz": {
        "year": "2001-2007"
      },
      "Terraza": {
        "year": "2005-2007"
      },
      "Verano": {
        "year": "2012-2016"
      }
    }
  },
  "BYD": {
    "model": {
      "Qin": {
        "year": "2014-2020"
      }
    }
  },
  "Cadillac": {
    "model": {
      "ATS": {
        "year": "2012-2019"
      },
      "ATS-V": {
        "year": "2016-2019"
      },
      "BLS": {
        "year": "2006-2010"
      },
      "CT4": {
        "year": "2020-"
      },
      "CT4-V": {
        "year": "2020-"
      },
      "CT5": {
        "year": "2019-"
      },
      "CT5-V": {
        "year": "2020-"
      },
      "CT6": {
        "year": "2016-"
      },
      "CTS": {
        "year": "2002-2019"
      },
      "De Ville": {
        "year": "2000-2005"
      },
      "DTS": {
        "year": "2006-2011"
      },
      "Eldorado": {
        "year": "1992-2002"
      },
      "ELR": {
        "year": "2013-2016"
      },
      "Escalade": {
        "year": "2002-"
      },
      "Seville": {
        "year": "1998-2004"
      },
      "SRX": {
        "year": "2004-2016"
      },
      "STS": {
        "year": "2004-2012"
      },
      "XLR": {
        "year": "2003-2009"
      },
      "XT4": {
        "year": "2018-"
      },
      "XT5": {
        "year": "2016-"
      },
      "XT6": {
        "year": "2019-"
      },
      "XTS": {
        "year": "2012-2019"
      }
    }
  },
  "Changan": {
    "model": {
      "CS35": {
        "year": "2012-"
      },
      "CS35 Plus": {
        "year": "2018-"
      },
      "CS55": {
        "year": "2017-"
      },
      "CS55 Plus": {
        "year": "2019-"
      },
      "CS75": {
        "year": "2013-"
      },
      "CS75 Plus": {
        "year": "2019-"
      },
      "CS95": {
        "year": "2017-"
      },
      "Eado": {
        "year": "2011-"
      },
      "Raeton": {
        "year": "2013-2017"
      },
      "Raeton CC": {
        "year": "2017-"
      },
      "Uni-K": {
        "year": "2021-"
      },
      "Uni-T": {
        "year": "2020-"
      },
      "Uni-V": {
        "year": "2021-"
      }
    }
  },
  "Chery": {
    "model": {
      "Amulet": {
        "year": "2003-2010"
      },
      "Arrizo 5 Plus": {
        "year": "2021-"
      },
      "Arrizo 6": {
        "year": "2021-"
      },
      "Arrizo 6 Pro": {
        "year": "2021-"
      },
      "Arrizo 7": {
        "year": "2013-2020"
      },
      "Arrizo 8": {
        "year": "2022-"
      },
      "Bonus": {
        "year": "2011-2016"
      },
      "Bonus 3": {
        "year": "2013-2016"
      },
      "CrossEastar": {
        "year": "2006-2015"
      },
      "Eastar": {
        "year": "2003-2015"
      },
      "eQ": {
        "year": "2015-2020"
      },
      "eQ1": {
        "year": "2017-"
      },
      "eQ5": {
        "year": "2020-"
      },
      "Fora": {
        "year": "2006-2010"
      },
      "IndiS": {
        "year": "2011-2016"
      },
      "Kimo": {
        "year": "2008-2014"
      },
      "M11": {
        "year": "2008-2017"
      },
      "Omoda 5": {
        "year": "0-"
      },
      "QQ": {
        "year": "2003-"
      },
      "QQ3": {
        "year": "2003-2018"
      },
      "QQ6": {
        "year": "2006-2010"
      },
      "Tiggo": {
        "year": "2006-2016"
      },
      "Tiggo 3": {
        "year": "2014-"
      },
      "Tiggo 4": {
        "year": "2017-"
      },
      "Tiggo 4 Pro": {
        "year": "2021-"
      },
      "Tiggo 5": {
        "year": "2014-"
      },
      "Tiggo 7": {
        "year": "2016-"
      },
      "Tiggo 7 Pro": {
        "year": "2020-"
      },
      "Tiggo 8": {
        "year": "2018-"
      },
      "Tiggo 8 Plus": {
        "year": "2020-"
      },
      "Tiggo 8 Pro": {
        "year": "2021-"
      },
      "Tiggo 8 Pro Max": {
        "year": "2022-"
      },
      "Tiggo e": {
        "year": "2019-"
      },
      "Very": {
        "year": "2011-2016"
      }
    }
  },
  "Chevrolet": {
    "model": {
      "Astro": {
        "year": "1995-2005"
      },
      "Avalanche": {
        "year": "2002-2013"
      },
      "Aveo": {
        "year": "2004-"
      },
      "Beat": {
        "year": "2010-"
      },
      "Blazer": {
        "year": "1994-2005"
      },
      "Bolt": {
        "year": "2017-"
      },
      "Bolt EUV": {
        "year": "2021-"
      },
      "Camaro": {
        "year": "1998-"
      },
      "Captiva": {
        "year": "2006-2018"
      },
      "Cavalier": {
        "year": "1995-2005"
      },
      "Cobalt": {
        "year": "2005-"
      },
      "Colorado": {
        "year": "2004-"
      },
      "Corvette": {
        "year": "2005-"
      },
      "Cruze": {
        "year": "2009-"
      },
      "Epica": {
        "year": "2006-2012"
      },
      "Equinox": {
        "year": "2005-"
      },
      "Express": {
        "year": "2003-"
      },
      "HHR": {
        "year": "2006-2011"
      },
      "Impala": {
        "year": "2006-2020"
      },
      "Lacetti": {
        "year": "2002-2012"
      },
      "Lanos": {
        "year": "1997-2012"
      },
      "Malibu": {
        "year": "2004-"
      },
      "Monte Carlo": {
        "year": "2005-2007"
      },
      "Niva": {
        "year": "2002-2020"
      },
      "Orlando": {
        "year": "2011-2018"
      },
      "Rezzo": {
        "year": "2000-2012"
      },
      "Silverado": {
        "year": "1999-"
      },
      "Silverado 1500": {
        "year": "1999-"
      },
      "Silverado 2500 HD": {
        "year": "2001-"
      },
      "Spark": {
        "year": "2005-"
      },
      "SSR": {
        "year": "2003-2006"
      },
      "Suburban": {
        "year": "2001-"
      },
      "Tahoe": {
        "year": "2000-"
      },
      "TrailBlazer": {
        "year": "2002-"
      },
      "Traverse": {
        "year": "2009-"
      },
      "Trax": {
        "year": "2013-"
      },
      "Uplander": {
        "year": "2005-2009"
      },
      "Venture": {
        "year": "1996-2005"
      }
    }
  },
  "Chrysler": {
    "model": {
      "200": {
        "year": "2010-2017"
      },
      "300": {
        "year": "2004-"
      },
      "300M": {
        "year": "1998-2004"
      },
      "Aspen": {
        "year": "2006-2009"
      },
      "Concorde": {
        "year": "1998-2004"
      },
      "Crossfire": {
        "year": "2003-2007"
      },
      "Grand Caravan": {
        "year": "2021-"
      },
      "Grand Voyager": {
        "year": "2008-2015"
      },
      "Pacifica": {
        "year": "2004-"
      },
      "PT Cruiser": {
        "year": "2000-2010"
      },
      "Sebring": {
        "year": "2000-2010"
      },
      "Town & Country": {
        "year": "2008-2016"
      },
      "Voyager": {
        "year": "1995-"
      }
    }
  },
  "Citroen": {
    "model": {
      "Berlingo": {
        "year": "1996-"
      },
      "C-Crosser": {
        "year": "2007-2012"
      },
      "C-Elysee": {
        "year": "2012-2020"
      },
      "C1": {
        "year": "2005-"
      },
      "C2": {
        "year": "2003-2009"
      },
      "C3": {
        "year": "2002-"
      },
      "C3 Aircross": {
        "year": "2017-"
      },
      "C3 Picasso": {
        "year": "2009-2017"
      },
      "C3 Pluriel": {
        "year": "2003-2010"
      },
      "C4": {
        "year": "2004-"
      },
      "C4 Aircross": {
        "year": "2012-2017"
      },
      "C4 Cactus": {
        "year": "2014-"
      },
      "C4 Picasso": {
        "year": "2006-"
      },
      "C4 SpaceTourer": {
        "year": "2018-"
      },
      "C5": {
        "year": "2001-"
      },
      "C5 Aircross": {
        "year": "2017-"
      },
      "C6": {
        "year": "2004-2012"
      },
      "C8": {
        "year": "2002-2014"
      },
      "DS 7 Crossback": {
        "year": "2018-"
      },
      "DS3": {
        "year": "2009-2019"
      },
      "DS4": {
        "year": "2010-2018"
      },
      "DS5": {
        "year": "2011-2018"
      },
      "Grand C4 Picasso": {
        "year": "2006-"
      },
      "Grand C4 SpaceTourer": {
        "year": "2018-"
      },
      "Jumper": {
        "year": "2014-"
      },
      "Jumpy": {
        "year": "2007-2016"
      },
      "Nemo": {
        "year": "2008-2018"
      },
      "Saxo": {
        "year": "1996-2004"
      },
      "Spacetourer": {
        "year": "2016-"
      },
      "Xsara": {
        "year": "1997-2006"
      },
      "Xsara Picasso": {
        "year": "1999-2012"
      }
    }
  },
  "Dacia": {
    "model": {
      "Dokker": {
        "year": "2012-"
      },
      "Lodgy": {
        "year": "2012-"
      },
      "Solenza": {
        "year": "2003-2005"
      },
      "Spring": {
        "year": "2021-"
      },
      "SupeRNova": {
        "year": "2000-2003"
      }
    }
  },
  "Daewoo": {
    "model": {
      "Evanda": {
        "year": "2002-2007"
      },
      "Kalos": {
        "year": "2002-2007"
      },
      "Leganza": {
        "year": "1997-2002"
      },
      "Magnus": {
        "year": "2000-2006"
      },
      "Matiz": {
        "year": "2000-2015"
      },
      "Nexia": {
        "year": "1995-2016"
      },
      "Nubira": {
        "year": "1997-2008"
      }
    }
  },
  "Daihatsu": {
    "model": {
      "Applause": {
        "year": "1997-2000"
      },
      "Cast": {
        "year": "2015-"
      },
      "Copen": {
        "year": "2002-"
      },
      "Cuore": {
        "year": "2003-2011"
      },
      "Gran Move": {
        "year": "1996-2002"
      },
      "Luxio": {
        "year": "2009-"
      },
      "Materia": {
        "year": "2006-2012"
      },
      "Mebius": {
        "year": "2013-"
      },
      "Move": {
        "year": "2014-"
      },
      "Rocky": {
        "year": "2019-"
      },
      "Sirion": {
        "year": "1998-2004"
      },
      "Terios": {
        "year": "1997-"
      },
      "Trevis": {
        "year": "2006-2011"
      },
      "YRV": {
        "year": "2000-2005"
      }
    }
  },
  "Datsun": {
    "model": {
      "mi-DO": {
        "year": "2014-"
      },
      "on-DO": {
        "year": "2014-"
      }
    }
  },
  "Dodge": {
    "model": {
      "Avenger": {
        "year": "1994-2014"
      },
      "Caliber": {
        "year": "2006-2012"
      },
      "Caliber SRT4": {
        "year": "2007-2010"
      },
      "Caravan": {
        "year": "1995-2016"
      },
      "Challenger": {
        "year": "2008-"
      },
      "Charger": {
        "year": "2006-"
      },
      "Dakota": {
        "year": "2005-2011"
      },
      "Dart": {
        "year": "2012-2017"
      },
      "Durango": {
        "year": "1998-"
      },
      "Intrepid": {
        "year": "1998-2004"
      },
      "Journey": {
        "year": "2008-"
      },
      "Magnum": {
        "year": "2004-2008"
      },
      "Neon": {
        "year": "2000-"
      },
      "Nitro": {
        "year": "2007-2012"
      },
      "Ram 1500": {
        "year": "2001-"
      },
      "Ram 2500": {
        "year": "2002-"
      },
      "Ram 3500": {
        "year": "2002-"
      },
      "Ram SRT10": {
        "year": "2004-2007"
      },
      "Stratus": {
        "year": "2001-2006"
      },
      "Viper": {
        "year": "1996-2017"
      }
    }
  },
  "Dongfeng": {
    "model": {
      "580": {
        "year": "2016-"
      },
      "A30": {
        "year": "2014-"
      },
      "AX7": {
        "year": "2014-"
      },
      "H30 Cross": {
        "year": "2013-"
      }
    }
  },
  "Exeed": {
    "model": {
      "TXL": {
        "year": "2019-"
      },
      "VX": {
        "year": "2020-"
      }
    }
  },
  "FAW": {
    "model": {
      "Bestune T77": {
        "year": "2019-"
      },
      "Besturn B30": {
        "year": "2016-2020"
      },
      "Besturn B50": {
        "year": "2009-2016"
      },
      "Besturn B70": {
        "year": "2020-"
      },
      "Besturn X40": {
        "year": "2017-"
      },
      "Besturn X80": {
        "year": "2014-"
      },
      "Oley": {
        "year": "2012-2018"
      },
      "Vita": {
        "year": "2006-2012"
      }
    }
  },
  "Ferrari": {
    "model": {
      "296": {
        "year": "2022-"
      },
      "348": {
        "year": "1993-1995"
      },
      "360": {
        "year": "1999-2005"
      },
      "456": {
        "year": "1992-2003"
      },
      "458": {
        "year": "2009-2016"
      },
      "488": {
        "year": "2015-2020"
      },
      "512": {
        "year": "1991-2004"
      },
      "550": {
        "year": "1996-2002"
      },
      "612": {
        "year": "2004-2011"
      },
      "812": {
        "year": "2017-"
      },
      "575 M": {
        "year": "2002-2006"
      },
      "599 GTB Fiorano": {
        "year": "2006-2012"
      },
      "599 GTO": {
        "year": "2010-2012"
      },
      "California": {
        "year": "2008-2014"
      },
      "California T": {
        "year": "2014-2017"
      },
      "Challenge Stradale": {
        "year": "2003-2003"
      },
      "Enzo": {
        "year": "2002-2004"
      },
      "F12": {
        "year": "2012-2017"
      },
      "F355": {
        "year": "1994-1999"
      },
      "F430": {
        "year": "2004-2010"
      },
      "F50": {
        "year": "1995-1997"
      },
      "F512 M": {
        "year": "1994-1996"
      },
      "F8 Spider": {
        "year": "2019-"
      },
      "F8 Tributo": {
        "year": "2019-"
      },
      "FF": {
        "year": "2011-2017"
      },
      "GTC4 Lusso": {
        "year": "2016-2020"
      },
      "LaFerrari": {
        "year": "2013-2015"
      },
      "Portofino": {
        "year": "2017-"
      },
      "Portofino M": {
        "year": "2020-"
      },
      "Roma": {
        "year": "2020-"
      },
      "SF90 Spider": {
        "year": "2020-"
      },
      "SF90 Stradale": {
        "year": "2019-"
      }
    }
  },
  "Fiat": {
    "model": {
      "500": {
        "year": "2007-"
      },
      "124 Spider": {
        "year": "2015-2020"
      },
      "500L": {
        "year": "2012-"
      },
      "500X": {
        "year": "2014-"
      },
      "Albea": {
        "year": "2002-2012"
      },
      "Brava": {
        "year": "1995-2003"
      },
      "Bravo": {
        "year": "1995-2016"
      },
      "Coupe": {
        "year": "1993-2009"
      },
      "Croma": {
        "year": "2005-2011"
      },
      "Doblo": {
        "year": "2001-"
      },
      "Ducato": {
        "year": "2006-"
      },
      "Freemont": {
        "year": "2011-2016"
      },
      "Grande Punto": {
        "year": "2005-2009"
      },
      "Idea": {
        "year": "2004-2016"
      },
      "Linea": {
        "year": "2007-2015"
      },
      "Marea": {
        "year": "1996-2006"
      },
      "Multipla": {
        "year": "1998-2010"
      },
      "Palio": {
        "year": "1996-2017"
      },
      "Panda": {
        "year": "2003-"
      },
      "Panda 4x4": {
        "year": "2003-2012"
      },
      "Punto": {
        "year": "1999-2018"
      },
      "Qubo": {
        "year": "2008-"
      },
      "Sedici": {
        "year": "2005-2014"
      },
      "Siena": {
        "year": "2014-2021"
      },
      "Stilo": {
        "year": "2001-2010"
      },
      "Strada": {
        "year": "2014-"
      },
      "Tipo": {
        "year": "2015-"
      },
      "Ulysse": {
        "year": "2002-2010"
      }
    }
  },
  "Fisker": {
    "model": {
      "Karma": {
        "year": "2011-2012"
      }
    }
  },
  "Ford": {
    "model": {
      "B-Max": {
        "year": "2012-2017"
      },
      "Bronco": {
        "year": "2021-"
      },
      "Bronco Sport": {
        "year": "2021-"
      },
      "C-Max": {
        "year": "2003-2019"
      },
      "Cougar": {
        "year": "1998-2004"
      },
      "Crown Victoria": {
        "year": "1999-2011"
      },
      "EcoSport": {
        "year": "2003-"
      },
      "Edge": {
        "year": "2007-"
      },
      "Endura": {
        "year": "2018-"
      },
      "Equator": {
        "year": "2021-"
      },
      "Escape": {
        "year": "2001-"
      },
      "Excursion": {
        "year": "1999-2005"
      },
      "Expedition": {
        "year": "1998-"
      },
      "Explorer": {
        "year": "1995-"
      },
      "Explorer Sport Trac": {
        "year": "2007-2010"
      },
      "F-150": {
        "year": "1992-"
      },
      "F-250": {
        "year": "2011-"
      },
      "F-350": {
        "year": "2017-"
      },
      "Falcon": {
        "year": "2005-"
      },
      "Fiesta": {
        "year": "2002-"
      },
      "Five Hundred": {
        "year": "2005-2007"
      },
      "Flex": {
        "year": "2008-2019"
      },
      "Focus": {
        "year": "1998-"
      },
      "Focus Active": {
        "year": "2018-"
      },
      "Focus Electric": {
        "year": "2012-2018"
      },
      "Freestar": {
        "year": "2003-2009"
      },
      "Freestyle": {
        "year": "2004-2007"
      },
      "Fusion": {
        "year": "2002-2012"
      },
      "Galaxy": {
        "year": "1995-"
      },
      "Ka": {
        "year": "2003-2021"
      },
      "Kuga": {
        "year": "2008-"
      },
      "Maverick": {
        "year": "1992-2009"
      },
      "Mondeo": {
        "year": "2000-"
      },
      "Mustang": {
        "year": "1995-"
      },
      "Mustang Mach-E": {
        "year": "2020-"
      },
      "Mustang Shelby GT350": {
        "year": "2016-"
      },
      "Mustang Shelby GT500": {
        "year": "2020-"
      },
      "Puma": {
        "year": "1997-"
      },
      "Ranger": {
        "year": "2006-"
      },
      "S-Max": {
        "year": "2006-"
      },
      "Taurus": {
        "year": "2000-"
      },
      "Taurus X": {
        "year": "2007-2009"
      },
      "Thunderbird": {
        "year": "2000-2006"
      },
      "Tourneo Connect": {
        "year": "2002-"
      },
      "Transit": {
        "year": "2001-"
      },
      "Transit Connect": {
        "year": "2002-"
      }
    }
  },
  "Foton": {
    "model": {
      "Sauvana": {
        "year": "2015-"
      }
    }
  },
  "GAC": {
    "model": {
      "GS5": {
        "year": "2011-"
      },
      "Trumpchi GM8": {
        "year": "2018-"
      },
      "Trumpchi GS8": {
        "year": "2017-"
      }
    }
  },
  "GAZ": {
    "model": {
      "3102": {
        "year": "1997-2009"
      },
      "31105": {
        "year": "1997-2009"
      },
      "Gazelle": {
        "year": "1994-2010"
      },
      "Gazelle Business": {
        "year": "2010-"
      },
      "Gazelle Next": {
        "year": "2013-"
      },
      "Gazelle NN": {
        "year": "2019-"
      },
      "Gazelle Sity": {
        "year": "2020-"
      },
      "Siber": {
        "year": "2008-2010"
      },
      "Sobol": {
        "year": "2002-"
      }
    }
  },
  "Geely": {
    "model": {
      "Atlas": {
        "year": "2017-"
      },
      "Atlas Pro": {
        "year": "2021-"
      },
      "Azkarra": {
        "year": "2019-"
      },
      "Coolray": {
        "year": "2018-"
      },
      "Emgrand 7": {
        "year": "2016-2020"
      },
      "Emgrand EC7": {
        "year": "2009-2017"
      },
      "Emgrand GS": {
        "year": "2016-2020"
      },
      "Emgrand X7": {
        "year": "2011-"
      },
      "GC9": {
        "year": "2015-2019"
      },
      "GС6": {
        "year": "2014-2016"
      },
      "MK": {
        "year": "2008-2019"
      },
      "Monjaro": {
        "year": "2021-"
      },
      "Otaka": {
        "year": "2006-2009"
      },
      "Preface": {
        "year": "2020-"
      },
      "Tugella": {
        "year": "2019-"
      },
      "Vision": {
        "year": "2006-2013"
      }
    }
  },
  "Genesis": {
    "model": {
      "G70": {
        "year": "2018-"
      },
      "G80": {
        "year": "2017-"
      },
      "G90": {
        "year": "2016-"
      },
      "GV60": {
        "year": "2021-"
      },
      "GV70": {
        "year": "2020-"
      },
      "GV80": {
        "year": "2020-"
      }
    }
  },
  "GMC": {
    "model": {
      "Acadia": {
        "year": "2007-"
      },
      "Canyon": {
        "year": "2004-"
      },
      "Envoy": {
        "year": "2001-2009"
      },
      "Sierra 1500": {
        "year": "1999-"
      },
      "Sierra 2500": {
        "year": "1999-"
      },
      "Sierra 3500": {
        "year": "2020-"
      },
      "Terrain": {
        "year": "2010-"
      },
      "Yukon": {
        "year": "2000-"
      }
    }
  },
  "Great Wall": {
    "model": {
      "Cowry": {
        "year": "2007-2014"
      },
      "Deer": {
        "year": "2003-2013"
      },
      "Hover": {
        "year": "2005-"
      },
      "Hover M2": {
        "year": "2010-2014"
      },
      "Pegasus": {
        "year": "2005-2007"
      },
      "Peri": {
        "year": "2007-2015"
      },
      "Poer": {
        "year": "2020-"
      },
      "Safe": {
        "year": "2002-2010"
      },
      "Sailor": {
        "year": "2004-2012"
      },
      "Sing": {
        "year": "2004-2012"
      },
      "Socool": {
        "year": "2004-2012"
      },
      "Wingle": {
        "year": "2008-"
      },
      "Wingle 7": {
        "year": "2018-"
      }
    }
  },
  "Haval": {
    "model": {
      "Dargo": {
        "year": "2020-"
      },
      "F7": {
        "year": "2018-"
      },
      "F7x": {
        "year": "2019-"
      },
      "H4": {
        "year": "2018-2021"
      },
      "H6": {
        "year": "2017-"
      },
      "H9": {
        "year": "2015-"
      },
      "Jolion": {
        "year": "2021-"
      }
    }
  },
  "Holden": {
    "model": {
      "Commodore": {
        "year": "2004-2017"
      },
      "Corvette C8": {
        "year": "2020-2021"
      }
    }
  },
  "Honda": {
    "model": {
      "Accord": {
        "year": "1998-"
      },
      "Amaze": {
        "year": "2011-"
      },
      "City": {
        "year": "2014-"
      },
      "Civic": {
        "year": "2001-"
      },
      "Civic Type R": {
        "year": "2017-"
      },
      "CR-V": {
        "year": "1995-"
      },
      "CR-Z": {
        "year": "2010-2016"
      },
      "Crosstour": {
        "year": "2010-2015"
      },
      "e": {
        "year": "2020-"
      },
      "Element": {
        "year": "2003-2011"
      },
      "Fit": {
        "year": "2001-"
      },
      "FR-V": {
        "year": "2004-2009"
      },
      "HR-V": {
        "year": "1998-"
      },
      "Insight": {
        "year": "1999-"
      },
      "Jade": {
        "year": "2013-"
      },
      "Jazz": {
        "year": "2001-"
      },
      "Legend": {
        "year": "2006-"
      },
      "Odyssey": {
        "year": "1999-"
      },
      "Passport": {
        "year": "2019-"
      },
      "Pilot": {
        "year": "2002-"
      },
      "Prelude": {
        "year": "1996-2001"
      },
      "Ridgeline": {
        "year": "2005-2014"
      },
      "S2000": {
        "year": "1999-2009"
      },
      "Shuttle": {
        "year": "1994-2002"
      },
      "Stepwgn": {
        "year": "2022-"
      },
      "Stream": {
        "year": "2001-2015"
      },
      "Vezel": {
        "year": "2013-"
      }
    }
  },
  "Hummer": {
    "model": {
      "H1": {
        "year": "1992-2006"
      },
      "H2": {
        "year": "2003-2010"
      },
      "H3": {
        "year": "2005-2010"
      }
    }
  },
  "Hyundai": {
    "model": {
      "Accent": {
        "year": "2000-"
      },
      "Atos Prime": {
        "year": "1999-2014"
      },
      "Azera": {
        "year": "2006-2011"
      },
      "Bayon": {
        "year": "2021-"
      },
      "Centennial": {
        "year": "1999-2008"
      },
      "Creta": {
        "year": "2015-"
      },
      "Creta Grand": {
        "year": "2021-"
      },
      "Elantra": {
        "year": "2000-"
      },
      "Entourage": {
        "year": "2007-2007"
      },
      "Eon": {
        "year": "2011-2019"
      },
      "Equus": {
        "year": "2009-2016"
      },
      "Galloper": {
        "year": "1998-2003"
      },
      "Genesis": {
        "year": "2008-2016"
      },
      "Genesis Coupe": {
        "year": "2008-2016"
      },
      "Getz": {
        "year": "2002-2011"
      },
      "Grandeur": {
        "year": "2005-2017"
      },
      "H-1": {
        "year": "1998-"
      },
      "i10": {
        "year": "2008-"
      },
      "i20": {
        "year": "2008-"
      },
      "i20 N": {
        "year": "2021-"
      },
      "i30": {
        "year": "2007-"
      },
      "i30 N": {
        "year": "2017-"
      },
      "i40": {
        "year": "2011-2019"
      },
      "Ioniq": {
        "year": "2016-"
      },
      "Ioniq 5": {
        "year": "2021-"
      },
      "ix20": {
        "year": "2010-2020"
      },
      "ix35": {
        "year": "2010-2018"
      },
      "Kona": {
        "year": "2017-"
      },
      "Kona N": {
        "year": "2021-"
      },
      "Kusto": {
        "year": "2021-"
      },
      "Matrix": {
        "year": "2001-2014"
      },
      "Mistra": {
        "year": "2021-"
      },
      "Nexo": {
        "year": "2018-"
      },
      "Palisade": {
        "year": "2018-"
      },
      "Porter": {
        "year": "1996-"
      },
      "Santa Cruz": {
        "year": "2022-"
      },
      "Santa Fe": {
        "year": "2001-"
      },
      "Solaris": {
        "year": "2010-"
      },
      "Sonata": {
        "year": "1998-"
      },
      "Staria": {
        "year": "2021-"
      },
      "Terracan": {
        "year": "2001-2007"
      },
      "Trajet": {
        "year": "2000-2008"
      },
      "Tucson": {
        "year": "2004-"
      },
      "Veloster": {
        "year": "2011-"
      },
      "Venue": {
        "year": "2019-"
      },
      "Veracruz": {
        "year": "2007-2015"
      },
      "Verna": {
        "year": "2005-2011"
      },
      "Xcent": {
        "year": "2014-2021"
      },
      "XG": {
        "year": "1998-2005"
      }
    }
  },
  "Infiniti": {
    "model": {
      "EX": {
        "year": "2008-2014"
      },
      "FX": {
        "year": "2003-2013"
      },
      "G": {
        "year": "2003-2014"
      },
      "I35": {
        "year": "2002-2004"
      },
      "JX": {
        "year": "2012-2013"
      },
      "M": {
        "year": "2001-2013"
      },
      "Q30": {
        "year": "2016-"
      },
      "Q40": {
        "year": "2014-2015"
      },
      "Q45": {
        "year": "2002-2007"
      },
      "Q50": {
        "year": "2013-"
      },
      "Q60": {
        "year": "2013-"
      },
      "Q70": {
        "year": "2013-"
      },
      "QX30": {
        "year": "2016-2020"
      },
      "QX4": {
        "year": "1997-2003"
      },
      "QX50": {
        "year": "2013-"
      },
      "QX55": {
        "year": "2021-"
      },
      "QX56": {
        "year": "2004-2013"
      },
      "QX60": {
        "year": "2013-"
      },
      "QX70": {
        "year": "2013-2017"
      },
      "QX80": {
        "year": "2013-"
      }
    }
  },
  "Isuzu": {
    "model": {
      "Ascender": {
        "year": "2002-2008"
      },
      "Axiom": {
        "year": "2002-2004"
      },
      "D-Max": {
        "year": "2011-"
      },
      "D-Max Rodeo": {
        "year": "2007-2007"
      },
      "I280": {
        "year": "2005-2006"
      },
      "I290": {
        "year": "2007-2007"
      },
      "I350": {
        "year": "2005-2006"
      },
      "I370": {
        "year": "2007-2007"
      },
      "mu-X": {
        "year": "2020-"
      },
      "Rodeo": {
        "year": "1998-2004"
      },
      "Trooper": {
        "year": "1992-2002"
      },
      "VehiCross": {
        "year": "1997-2001"
      }
    }
  },
  "Iveco": {
    "model": {
      "Daily": {
        "year": "2006-"
      }
    }
  },
  "Jac": {
    "model": {
      "iEV7S": {
        "year": "2017-"
      },
      "T6": {
        "year": "2015-"
      }
    }
  },
  "Jaguar": {
    "model": {
      "E-Pace": {
        "year": "2017-"
      },
      "F-Pace": {
        "year": "2015-"
      },
      "F-Type": {
        "year": "2013-"
      },
      "I-Pace": {
        "year": "2018-"
      },
      "S-Type": {
        "year": "1998-2008"
      },
      "X-Type": {
        "year": "2001-2009"
      },
      "XE": {
        "year": "2015-"
      },
      "XF": {
        "year": "2007-"
      },
      "XJ": {
        "year": "1997-"
      },
      "XK/XKR": {
        "year": "2002-2015"
      }
    }
  },
  "Jeep": {
    "model": {
      "Cherokee": {
        "year": "2002-"
      },
      "Commander": {
        "year": "2006-"
      },
      "Compass": {
        "year": "2007-"
      },
      "Gladiator": {
        "year": "2019-"
      },
      "Grand Cherokee": {
        "year": "1999-"
      },
      "Grand Wagoneer": {
        "year": "2022-"
      },
      "Liberty": {
        "year": "2006-2013"
      },
      "Meridian": {
        "year": "2021-"
      },
      "Patriot": {
        "year": "2006-2016"
      },
      "Renegade": {
        "year": "2014-"
      },
      "Wagoneer": {
        "year": "2021-"
      },
      "Wrangler": {
        "year": "1997-"
      }
    }
  },
  "Kia": {
    "model": {
      "Carens": {
        "year": "2002-"
      },
      "Carnival": {
        "year": "1999-"
      },
      "Ceed": {
        "year": "2006-"
      },
      "Cerato": {
        "year": "2004-"
      },
      "Clarus": {
        "year": "1998-2001"
      },
      "EV6": {
        "year": "2021-"
      },
      "Forte": {
        "year": "2008-"
      },
      "K5": {
        "year": "2020-"
      },
      "K8": {
        "year": "2021-"
      },
      "K900": {
        "year": "2014-"
      },
      "Magentis": {
        "year": "2001-2010"
      },
      "Mohave": {
        "year": "2008-"
      },
      "Niro": {
        "year": "2016-"
      },
      "Opirus": {
        "year": "2003-2010"
      },
      "Optima": {
        "year": "2010-2020"
      },
      "Picanto": {
        "year": "2004-"
      },
      "ProCeed": {
        "year": "2019-"
      },
      "Quoris": {
        "year": "2012-2018"
      },
      "Ray": {
        "year": "2011-"
      },
      "Rio": {
        "year": "2000-"
      },
      "Rio X": {
        "year": "2020-"
      },
      "Rio X-Line": {
        "year": "2017-"
      },
      "Seltos": {
        "year": "2019-"
      },
      "Shuma": {
        "year": "1997-2005"
      },
      "Sonet": {
        "year": "2020-"
      },
      "Sorento": {
        "year": "2002-"
      },
      "Sorento Prime": {
        "year": "2015-2020"
      },
      "Soul": {
        "year": "2009-"
      },
      "Spectra": {
        "year": "2005-2009"
      },
      "Sportage": {
        "year": "1993-"
      },
      "Stinger": {
        "year": "2017-"
      },
      "Stonic": {
        "year": "2017-"
      },
      "Telluride": {
        "year": "2019-"
      },
      "Venga": {
        "year": "2009-2019"
      }
    }
  },
  "Lamborghini": {
    "model": {
      "Aventador": {
        "year": "2011-"
      },
      "Centenario": {
        "year": "2016-2017"
      },
      "Diablo": {
        "year": "1991-2001"
      },
      "Gallardo": {
        "year": "2003-2014"
      },
      "Huracan": {
        "year": "2014-"
      },
      "Murcielago": {
        "year": "2003-2010"
      },
      "Reventon": {
        "year": "2008-2008"
      },
      "Sian": {
        "year": "2021-"
      },
      "Urus": {
        "year": "2018-"
      }
    }
  },
  "Lancia": {
    "model": {
      "Delta": {
        "year": "2008-2014"
      },
      "Lybra": {
        "year": "1999-2007"
      },
      "Musa": {
        "year": "2004-2012"
      },
      "Phedra": {
        "year": "2002-2010"
      },
      "Thema": {
        "year": "2011-2014"
      },
      "Thesis": {
        "year": "2001-2009"
      },
      "Ypsilon": {
        "year": "2003-"
      }
    }
  },
  "Land Rover": {
    "model": {
      "Defender": {
        "year": "2007-"
      },
      "Discovery": {
        "year": "1998-"
      },
      "Discovery Sport": {
        "year": "2014-"
      },
      "Evoque": {
        "year": "2011-"
      },
      "Freelander": {
        "year": "1998-2014"
      },
      "Range Rover": {
        "year": "1994-"
      },
      "Range Rover Sport": {
        "year": "2004-"
      },
      "Range Rover Velar": {
        "year": "2017-"
      }
    }
  },
  "Lexus": {
    "model": {
      "CT": {
        "year": "2010-"
      },
      "ES": {
        "year": "2001-"
      },
      "GS": {
        "year": "1997-2022"
      },
      "GX": {
        "year": "2002-"
      },
      "HS": {
        "year": "2009-2014"
      },
      "IS": {
        "year": "1998-"
      },
      "LC": {
        "year": "2017-"
      },
      "LFA": {
        "year": "2010-2012"
      },
      "LM": {
        "year": "2019-"
      },
      "LS": {
        "year": "1995-"
      },
      "LX": {
        "year": "1998-"
      },
      "NX": {
        "year": "2014-"
      },
      "RC": {
        "year": "2014-"
      },
      "RC F": {
        "year": "2014-"
      },
      "RX": {
        "year": "1997-"
      },
      "SC": {
        "year": "1999-2010"
      },
      "UX": {
        "year": "2018-"
      }
    }
  },
  "Lifan": {
    "model": {
      "Breez": {
        "year": "2007-2012"
      },
      "Cebrium": {
        "year": "2013-2017"
      },
      "Celliya": {
        "year": "2013-2016"
      },
      "Murman": {
        "year": "2017-"
      },
      "Myway": {
        "year": "2017-"
      },
      "Smily": {
        "year": "2008-2018"
      },
      "Solano": {
        "year": "2008-"
      },
      "X50": {
        "year": "2014-"
      },
      "X60": {
        "year": "2011-"
      },
      "X70": {
        "year": "2018-"
      },
      "X80": {
        "year": "2017-"
      }
    }
  },
  "Lincoln": {
    "model": {
      "Aviator": {
        "year": "2003-"
      },
      "Corsair": {
        "year": "2019-"
      },
      "Mark LT": {
        "year": "2006-2007"
      },
      "MKC": {
        "year": "2014-2019"
      },
      "MKS": {
        "year": "2008-2016"
      },
      "MKT": {
        "year": "2009-2019"
      },
      "MKX": {
        "year": "2006-2018"
      },
      "MKZ": {
        "year": "2006-2020"
      },
      "Nautilus": {
        "year": "2019-"
      },
      "Navigator": {
        "year": "1997-"
      },
      "Town Car": {
        "year": "1998-2011"
      },
      "Zephyr": {
        "year": "2006-2006"
      }
    }
  },
  "Lotus": {
    "model": {
      "Elise": {
        "year": "2001-"
      },
      "Europa S": {
        "year": "2005-2010"
      },
      "Evora": {
        "year": "2009-"
      },
      "Exige": {
        "year": "2001-"
      }
    }
  },
  "Marussia": {
    "model": {
      "B1": {
        "year": "2008-2014"
      },
      "B2": {
        "year": "2010-2014"
      }
    }
  },
  "Maserati": {
    "model": {
      "3200 GT": {
        "year": "1998-2002"
      },
      "Ghibli": {
        "year": "2013-"
      },
      "Gran Cabrio": {
        "year": "2010-2019"
      },
      "Gran Turismo ": {
        "year": "2007-2019"
      },
      "Gran Turismo S": {
        "year": "2008-2012"
      },
      "Grecale": {
        "year": "2022-"
      },
      "Levante": {
        "year": "2016-"
      },
      "MC20": {
        "year": "2020-"
      },
      "Quattroporte": {
        "year": "2003-"
      },
      "Quattroporte S": {
        "year": "2007-2012"
      }
    }
  },
  "Maybach": {
    "model": {
      "57": {
        "year": "2002-2012"
      },
      "62": {
        "year": "2002-2012"
      },
      "57 S": {
        "year": "2007-2012"
      },
      "62 S": {
        "year": "2007-2012"
      },
      "Landaulet": {
        "year": "2003-2012"
      }
    }
  },
  "Mazda": {
    "model": {
      "2": {
        "year": "2003-"
      },
      "3": {
        "year": "2003-"
      },
      "5": {
        "year": "2005-"
      },
      "6": {
        "year": "2002-"
      },
      "323": {
        "year": "1998-2003"
      },
      "626": {
        "year": "1997-2001"
      },
      "B-Series": {
        "year": "1999-2006"
      },
      "BT-50": {
        "year": "2011-"
      },
      "CX-3": {
        "year": "2015-"
      },
      "CX-30": {
        "year": "2019-"
      },
      "CX-30 EV": {
        "year": "2021-"
      },
      "CX-4": {
        "year": "2017-"
      },
      "CX-5": {
        "year": "2012-"
      },
      "CX-60": {
        "year": "2022-"
      },
      "CX-7": {
        "year": "2006-2012"
      },
      "CX-8": {
        "year": "2017-"
      },
      "CX-9": {
        "year": "2007-"
      },
      "MPV": {
        "year": "1999-2016"
      },
      "MX-30": {
        "year": "2020-"
      },
      "MX-5": {
        "year": "1998-"
      },
      "Premacy": {
        "year": "1999-2006"
      },
      "RX-7": {
        "year": "1992-2002"
      },
      "RX-8": {
        "year": "2003-2012"
      },
      "Tribute": {
        "year": "2000-2007"
      }
    }
  },
  "McLaren": {
    "model": {
      "540C": {
        "year": "2015-"
      },
      "570S": {
        "year": "2015-"
      },
      "600LT": {
        "year": "2018-"
      },
      "650S": {
        "year": "2014-2017"
      },
      "675LT": {
        "year": "2015-2017"
      },
      "720S": {
        "year": "2017-"
      },
      "720S Spider": {
        "year": "2019-"
      },
      "765LT": {
        "year": "2020-"
      },
      "Artura": {
        "year": "2021-"
      },
      "MP4-12C": {
        "year": "2011-2014"
      },
      "P1": {
        "year": "2013-2016"
      }
    }
  },
  "Mercedes": {
    "model": {
      "A-class": {
        "year": "1997-"
      },
      "AMG GT": {
        "year": "2014-"
      },
      "AMG GT 4-Door": {
        "year": "2018-"
      },
      "B-class": {
        "year": "2005-"
      },
      "C-class": {
        "year": "1997-"
      },
      "C-class Sport Coupe": {
        "year": "2001-2007"
      },
      "Citan": {
        "year": "2012-"
      },
      "CL-class": {
        "year": "1992-2014"
      },
      "CLA-class": {
        "year": "2013-"
      },
      "CLC-class ": {
        "year": "2008-2011"
      },
      "CLK-class": {
        "year": "1997-2010"
      },
      "CLS-class": {
        "year": "2004-"
      },
      "E-class": {
        "year": "1995-"
      },
      "E-class Coupe": {
        "year": "2017-"
      },
      "EQA": {
        "year": "2021-"
      },
      "EQB": {
        "year": "2021-"
      },
      "EQC": {
        "year": "2019-"
      },
      "EQE": {
        "year": "2022-"
      },
      "EQE AMG": {
        "year": "2022-"
      },
      "EQS": {
        "year": "2021-"
      },
      "EQS AMG": {
        "year": "2022-"
      },
      "EQV": {
        "year": "2021-"
      },
      "G-class": {
        "year": "1996-"
      },
      "GL-class": {
        "year": "2006-2016"
      },
      "GLA-class": {
        "year": "2013-"
      },
      "GLA-class AMG": {
        "year": "2013-"
      },
      "GLB-class": {
        "year": "2019-"
      },
      "GLC-class": {
        "year": "2015-"
      },
      "GLC-class AMG": {
        "year": "2015-"
      },
      "GLC-class Coupe": {
        "year": "2016-"
      },
      "GLE-class": {
        "year": "2015-"
      },
      "GLE-class AMG": {
        "year": "2015-"
      },
      "GLE-class Coupe": {
        "year": "2015-"
      },
      "GLK-class": {
        "year": "2008-2015"
      },
      "GLS-class": {
        "year": "2015-"
      },
      "GLS-class AMG": {
        "year": "2015-"
      },
      "M-class": {
        "year": "1997-"
      },
      "R-class": {
        "year": "2005-2012"
      },
      "S-class": {
        "year": "1990-"
      },
      "S-class Cabrio": {
        "year": "2015-2021"
      },
      "S-class Coupe": {
        "year": "2015-2021"
      },
      "SL-class": {
        "year": "2001-2021"
      },
      "SL-Class AMG": {
        "year": "2022-"
      },
      "SLC-class": {
        "year": "2018-"
      },
      "SLK-class": {
        "year": "1996-"
      },
      "SLR-class": {
        "year": "2003-2010"
      },
      "SLS AMG": {
        "year": "2010-2014"
      },
      "Sprinter": {
        "year": "2000-"
      },
      "Vaneo": {
        "year": "2001-2006"
      },
      "Viano": {
        "year": "2003-"
      },
      "Vito": {
        "year": "1999-2003"
      },
      "X-class": {
        "year": "2018-"
      }
    }
  },
  "Mercury": {
    "model": {
      "Grand Marquis": {
        "year": "2003-2011"
      },
      "Mariner": {
        "year": "2005-2007"
      },
      "Milan": {
        "year": "2006-2011"
      },
      "Montego": {
        "year": "2004-2007"
      },
      "Monterey": {
        "year": "1991-2007"
      },
      "Mountaineer": {
        "year": "2003-2010"
      },
      "Sable": {
        "year": "1995-2005"
      }
    }
  },
  "MG": {
    "model": {
      "Hector": {
        "year": "2019-"
      },
      "TF": {
        "year": "2002-2011"
      },
      "XPower SV": {
        "year": "2003-2008"
      },
      "ZR": {
        "year": "2001-2005"
      },
      "ZS": {
        "year": "2001-2005"
      },
      "ZS EV": {
        "year": "2021-"
      },
      "ZT": {
        "year": "2001-2005"
      },
      "ZT-T": {
        "year": "2001-2005"
      }
    }
  },
  "Mini": {
    "model": {
      "Clubman": {
        "year": "2007-"
      },
      "Clubman S": {
        "year": "2007-"
      },
      "Clubvan": {
        "year": "2012-2014"
      },
      "Cooper": {
        "year": "2001-"
      },
      "Cooper Cabrio": {
        "year": "2001-"
      },
      "Cooper S": {
        "year": "2001-"
      },
      "Cooper S Cabrio": {
        "year": "2001-"
      },
      "Cooper S Countryman All4": {
        "year": "2010-"
      },
      "Countryman": {
        "year": "2010-"
      },
      "One": {
        "year": "2001-"
      }
    }
  },
  "Mitsubishi": {
    "model": {
      "3000 GT": {
        "year": "1992-2001"
      },
      "ASX": {
        "year": "2010-"
      },
      "Carisma": {
        "year": "1995-2004"
      },
      "Colt": {
        "year": "1995-2012"
      },
      "Dignity": {
        "year": "2012-2017"
      },
      "Eclipse": {
        "year": "1995-2007"
      },
      "Eclipse Cross": {
        "year": "2017-"
      },
      "Endeavor": {
        "year": "2004-2011"
      },
      "Galant": {
        "year": "1996-2012"
      },
      "Grandis": {
        "year": "2003-2011"
      },
      "i-MiEV": {
        "year": "2009-"
      },
      "L200": {
        "year": "1996-"
      },
      "Lancer": {
        "year": "1996-"
      },
      "Lancer Evo": {
        "year": "2001-2016"
      },
      "Mirage": {
        "year": "2012-"
      },
      "Outlander": {
        "year": "2003-"
      },
      "Outlander Sport": {
        "year": "2010-"
      },
      "Outlander XL": {
        "year": "2006-2012"
      },
      "Pajero": {
        "year": "1990-"
      },
      "Pajero Pinin": {
        "year": "1999-2007"
      },
      "Pajero Sport": {
        "year": "1998-"
      },
      "Raider": {
        "year": "2006-2007"
      },
      "Space Gear": {
        "year": "1995-2007"
      },
      "Space Runner": {
        "year": "1999-2004"
      },
      "Space Star": {
        "year": "1998-2004"
      },
      "Xpander": {
        "year": "2017-"
      }
    }
  },
  "Nissan": {
    "model": {
      "350Z": {
        "year": "2002-2009"
      },
      "370Z": {
        "year": "2009-"
      },
      "Almera": {
        "year": "2000-"
      },
      "Almera Classic": {
        "year": "2005-2012"
      },
      "Almera Tino": {
        "year": "2000-2005"
      },
      "Altima": {
        "year": "2002-"
      },
      "Ariya": {
        "year": "2020-"
      },
      "Armada": {
        "year": "2003-"
      },
      "Bluebird Sylphy": {
        "year": "2000-2012"
      },
      "Frontier": {
        "year": "1998-"
      },
      "GT-R": {
        "year": "2007-"
      },
      "Juke": {
        "year": "2011-"
      },
      "Leaf": {
        "year": "2010-"
      },
      "Maxima": {
        "year": "2000-"
      },
      "Micra": {
        "year": "2003-"
      },
      "Murano": {
        "year": "2002-"
      },
      "Navara": {
        "year": "2005-"
      },
      "Note": {
        "year": "2005-"
      },
      "NP300": {
        "year": "2008-2013"
      },
      "Pathfinder": {
        "year": "1997-"
      },
      "Patrol": {
        "year": "1997-"
      },
      "Primera": {
        "year": "1996-2008"
      },
      "Qashqai": {
        "year": "2007-"
      },
      "Qashqai+2": {
        "year": "2008-2013"
      },
      "Quest": {
        "year": "2003-2017"
      },
      "Rogue": {
        "year": "2008-"
      },
      "Sentra": {
        "year": "1999-"
      },
      "Skyline": {
        "year": "2001-2005"
      },
      "Sylphy": {
        "year": "2012-2020"
      },
      "Teana": {
        "year": "2005-"
      },
      "Terrano": {
        "year": "1993-"
      },
      "Tiida": {
        "year": "2007-"
      },
      "Titan": {
        "year": "2003-"
      },
      "Titan XD": {
        "year": "2016-"
      },
      "X-Trail": {
        "year": "2001-"
      },
      "XTerra": {
        "year": "2001-2015"
      },
      "Z": {
        "year": "2003-"
      }
    }
  },
  "Opel": {
    "model": {
      "Adam": {
        "year": "2013-2020"
      },
      "Agila": {
        "year": "2000-2014"
      },
      "Ampera-e": {
        "year": "2017-"
      },
      "Antara": {
        "year": "2006-2015"
      },
      "Astra": {
        "year": "1998-"
      },
      "Astra GTC": {
        "year": "2011-2018"
      },
      "Astra OPC": {
        "year": "2011-2018"
      },
      "Cascada": {
        "year": "2013-2019"
      },
      "Combo": {
        "year": "2001-"
      },
      "Corsa": {
        "year": "2000-"
      },
      "Corsa OPC": {
        "year": "2007-2018"
      },
      "Crossland": {
        "year": "2020-"
      },
      "Crossland X": {
        "year": "2017-2020"
      },
      "Frontera": {
        "year": "1998-2004"
      },
      "Grandland": {
        "year": "2021-"
      },
      "Grandland X": {
        "year": "2017-"
      },
      "Insignia": {
        "year": "2008-"
      },
      "Insignia OPC": {
        "year": "2009-2017"
      },
      "Karl": {
        "year": "2014-2019"
      },
      "Meriva": {
        "year": "2003-2017"
      },
      "Mokka": {
        "year": "2012-"
      },
      "Omega": {
        "year": "1994-2004"
      },
      "Signum": {
        "year": "2003-2008"
      },
      "Speedster": {
        "year": "2000-2007"
      },
      "Tigra": {
        "year": "1994-2009"
      },
      "Vectra": {
        "year": "1995-2008"
      },
      "Vivaro": {
        "year": "2014-"
      },
      "Zafira": {
        "year": "1999-2014"
      },
      "Zafira Life": {
        "year": "2019-"
      },
      "Zafira Tourer": {
        "year": "2012-2020"
      }
    }
  },
  "Peugeot": {
    "model": {
      "107": {
        "year": "2005-2014"
      },
      "108": {
        "year": "2014-"
      },
      "206": {
        "year": "1998-2012"
      },
      "207": {
        "year": "2006-2014"
      },
      "208": {
        "year": "2012-"
      },
      "301": {
        "year": "2012-"
      },
      "307": {
        "year": "2000-2011"
      },
      "308": {
        "year": "2007-"
      },
      "406": {
        "year": "1995-2004"
      },
      "407": {
        "year": "2004-2010"
      },
      "408": {
        "year": "2010-"
      },
      "508": {
        "year": "2011-"
      },
      "607": {
        "year": "2000-2010"
      },
      "807": {
        "year": "2002-2014"
      },
      "1007": {
        "year": "2004-2009"
      },
      "2008": {
        "year": "2013-"
      },
      "3008": {
        "year": "2009-"
      },
      "4007": {
        "year": "2007-2012"
      },
      "4008": {
        "year": "2012-"
      },
      "5008": {
        "year": "2009-"
      },
      "Boxer": {
        "year": "2008-2014"
      },
      "Expert": {
        "year": "2016-"
      },
      "Landtrek": {
        "year": "2020-"
      },
      "Manager": {
        "year": "2017-"
      },
      "Partner": {
        "year": "1996-"
      },
      "RCZ Sport": {
        "year": "2010-2015"
      },
      "Rifter": {
        "year": "2018-"
      },
      "Traveller": {
        "year": "2016-"
      }
    }
  },
  "Plymouth": {
    "model": {
      "Road Runner": {
        "year": "1968-1970"
      }
    }
  },
  "Pontiac": {
    "model": {
      "Aztec": {
        "year": "2001-2005"
      },
      "Bonneville": {
        "year": "1999-2005"
      },
      "Firebird": {
        "year": "1993-2002"
      },
      "G5 Pursuit": {
        "year": "2004-2010"
      },
      "G6": {
        "year": "2004-2010"
      },
      "G8": {
        "year": "2008-2009"
      },
      "Grand AM": {
        "year": "1998-2005"
      },
      "Grand Prix": {
        "year": "1996-2008"
      },
      "GTO": {
        "year": "2004-2006"
      },
      "Montana": {
        "year": "1997-2009"
      },
      "Solstice": {
        "year": "2005-2010"
      },
      "Sunfire": {
        "year": "1995-2005"
      },
      "Torrent": {
        "year": "2005-2009"
      },
      "Vibe": {
        "year": "2002-2009"
      }
    }
  },
  "Porsche": {
    "model": {
      "911": {
        "year": "1997-"
      },
      "718 Boxster": {
        "year": "2016-"
      },
      "718 Cayman": {
        "year": "2016-"
      },
      "Boxster": {
        "year": "1996-2016"
      },
      "Cayenne": {
        "year": "2002-"
      },
      "Cayman": {
        "year": "2005-2016"
      },
      "Macan": {
        "year": "2014-"
      },
      "Panamera": {
        "year": "2009-"
      },
      "Taycan": {
        "year": "2020-"
      }
    }
  },
  "Ravon": {
    "model": {
      "Gentra": {
        "year": "2015-2019"
      }
    }
  },
  "Renault": {
    "model": {
      "Alaskan": {
        "year": "2016-2020"
      },
      "Arkana": {
        "year": "2019-"
      },
      "Avantime": {
        "year": "2001-2004"
      },
      "Captur": {
        "year": "2013-"
      },
      "Clio": {
        "year": "1998-"
      },
      "Duster": {
        "year": "2010-"
      },
      "Duster Oroch": {
        "year": "2015-"
      },
      "Espace": {
        "year": "1996-"
      },
      "Fluence": {
        "year": "2010-2019"
      },
      "Grand Scenic": {
        "year": "2009-"
      },
      "Kadjar": {
        "year": "2015-"
      },
      "Kangoo": {
        "year": "1998-"
      },
      "Kaptur": {
        "year": "2016-"
      },
      "Kiger": {
        "year": "2021-"
      },
      "Koleos": {
        "year": "2008-"
      },
      "Laguna": {
        "year": "1993-2015"
      },
      "Latitude": {
        "year": "2010-2018"
      },
      "Logan": {
        "year": "2004-"
      },
      "Logan Stepway": {
        "year": "2018-"
      },
      "Master": {
        "year": "2011-"
      },
      "Megane": {
        "year": "1996-"
      },
      "Modus": {
        "year": "2004-2012"
      },
      "Sandero": {
        "year": "2007-"
      },
      "Sandero Stepway": {
        "year": "2008-"
      },
      "Scenic": {
        "year": "1996-"
      },
      "Symbol": {
        "year": "2002-"
      },
      "Taliant": {
        "year": "2021-"
      },
      "Talisman": {
        "year": "2015-"
      },
      "Trafic": {
        "year": "2001-"
      },
      "Triber": {
        "year": "2019-"
      },
      "Twingo": {
        "year": "1993-"
      },
      "Twizy": {
        "year": "2012-"
      },
      "Vel Satis": {
        "year": "2002-2009"
      },
      "Wind": {
        "year": "2010-2013"
      },
      "Zoe": {
        "year": "2012-"
      }
    }
  },
  "Rolls-Royce": {
    "model": {
      "Cullinan": {
        "year": "2018-"
      },
      "Dawn": {
        "year": "2015-"
      },
      "Ghost": {
        "year": "2009-"
      },
      "Phantom": {
        "year": "2003-"
      },
      "Wraith": {
        "year": "2013-"
      }
    }
  },
  "Rover": {
    "model": {
      "25": {
        "year": "1999-2008"
      },
      "45": {
        "year": "2000-2008"
      },
      "75": {
        "year": "1999-2005"
      },
      "400": {
        "year": "1996-2000"
      },
      "600": {
        "year": "1999-2004"
      },
      "Streetwise": {
        "year": "2003-2005"
      }
    }
  },
  "Saab": {
    "model": {
      "9-2x": {
        "year": "2004-2007"
      },
      "9-3": {
        "year": "2003-2014"
      },
      "9-4x": {
        "year": "2011-2012"
      },
      "9-5": {
        "year": "1997-2012"
      },
      "9-7x": {
        "year": "2005-2009"
      }
    }
  },
  "Saturn": {
    "model": {
      "Aura": {
        "year": "2007-"
      },
      "Ion": {
        "year": "2003-2007"
      },
      "LW": {
        "year": "1998-2004"
      },
      "Outlook": {
        "year": "2006-2010"
      },
      "Sky": {
        "year": "2007-2007"
      },
      "Vue": {
        "year": "2002-2007"
      }
    }
  },
  "Scion": {
    "model": {
      "FR-S": {
        "year": "2012-2016"
      },
      "tC": {
        "year": "2004-2016"
      },
      "xA": {
        "year": "2003-2007"
      },
      "xB": {
        "year": "2003-2015"
      },
      "xD": {
        "year": "2007-2014"
      }
    }
  },
  "Seat": {
    "model": {
      "Alhambra": {
        "year": "1998-"
      },
      "Altea": {
        "year": "2004-2015"
      },
      "Altea Freetrack": {
        "year": "2007-2015"
      },
      "Altea XL": {
        "year": "2007-2015"
      },
      "Arona": {
        "year": "2017-"
      },
      "Arosa": {
        "year": "1997-2006"
      },
      "Ateca": {
        "year": "2016-"
      },
      "Cordoba": {
        "year": "1999-2009"
      },
      "Exeo": {
        "year": "2008-2013"
      },
      "Ibiza": {
        "year": "2002-"
      },
      "Leon": {
        "year": "1999-"
      },
      "Mii": {
        "year": "2012-"
      },
      "Tarraco": {
        "year": "2018-"
      },
      "Toledo": {
        "year": "2004-2018"
      }
    }
  },
  "Skoda": {
    "model": {
      "Citigo": {
        "year": "2011-2020"
      },
      "Enyaq iV": {
        "year": "2020-"
      },
      "Fabia": {
        "year": "1999-"
      },
      "Felicia": {
        "year": "1995-2000"
      },
      "Kamiq": {
        "year": "2019-"
      },
      "Karoq": {
        "year": "2017-"
      },
      "Kodiaq": {
        "year": "2016-"
      },
      "Octavia": {
        "year": "1995-"
      },
      "Octavia Scout": {
        "year": "2007-2013"
      },
      "Octavia Tour": {
        "year": "2006-2013"
      },
      "Praktik": {
        "year": "2007-2015"
      },
      "Rapid": {
        "year": "2012-"
      },
      "Rapid Spaceback (NH1)": {
        "year": "2013-2020"
      },
      "Roomster": {
        "year": "2006-2015"
      },
      "Scala": {
        "year": "2019-"
      },
      "Superb": {
        "year": "2002-"
      },
      "Yeti": {
        "year": "2009-"
      }
    }
  },
  "Smart": {
    "model": {
      "Forfour": {
        "year": "2004-"
      },
      "Fortwo": {
        "year": "1997-"
      },
      "Roadster": {
        "year": "2003-2012"
      }
    }
  },
  "Ssang Yong": {
    "model": {
      "Actyon": {
        "year": "2005-"
      },
      "Actyon Sports": {
        "year": "2006-"
      },
      "Chairman": {
        "year": "1997-"
      },
      "Korando": {
        "year": "2002-"
      },
      "Kyron": {
        "year": "2005-2016"
      },
      "Musso": {
        "year": "1993-"
      },
      "Musso Grand": {
        "year": "2020-"
      },
      "Musso Sport": {
        "year": "2004-2007"
      },
      "Rexton": {
        "year": "2001-"
      },
      "Rexton Sports": {
        "year": "2018-"
      },
      "Rodius": {
        "year": "2004-2019"
      },
      "Stavic": {
        "year": "2013-2019"
      },
      "Tivoli": {
        "year": "2015-"
      },
      "Tivoli Grand": {
        "year": "2021-"
      },
      "XLV": {
        "year": "2016-"
      }
    }
  },
  "Subaru": {
    "model": {
      "Ascent": {
        "year": "2018-"
      },
      "Baja": {
        "year": "2002-2007"
      },
      "BRZ": {
        "year": "2012-"
      },
      "Crosstrack": {
        "year": "2018-"
      },
      "Exiga": {
        "year": "2008-"
      },
      "Forester": {
        "year": "1997-"
      },
      "Impreza": {
        "year": "1992-"
      },
      "Justy": {
        "year": "1995-2006"
      },
      "Legacy": {
        "year": "1994-"
      },
      "Levorg": {
        "year": "2014-"
      },
      "Outback": {
        "year": "1997-"
      },
      "Traviq": {
        "year": "2001-2006"
      },
      "Tribeca": {
        "year": "2005-2014"
      },
      "WRX": {
        "year": "2014-"
      },
      "XV": {
        "year": "2012-"
      }
    }
  },
  "Suzuki": {
    "model": {
      "Alto": {
        "year": "2002-"
      },
      "Baleno": {
        "year": "1995-"
      },
      "Celerio": {
        "year": "2014-"
      },
      "Ciaz": {
        "year": "2014-"
      },
      "Ertiga": {
        "year": "2012-"
      },
      "Grand Vitara": {
        "year": "1998-2014"
      },
      "Grand Vitara XL7": {
        "year": "1998-2014"
      },
      "Ignis": {
        "year": "2000-"
      },
      "Jimny": {
        "year": "1998-"
      },
      "Kizashi": {
        "year": "2009-2015"
      },
      "Liana": {
        "year": "2002-2007"
      },
      "S-Presso": {
        "year": "2019-"
      },
      "Splash": {
        "year": "2008-2016"
      },
      "Swift": {
        "year": "2005-"
      },
      "SX4": {
        "year": "2006-"
      },
      "Vitara": {
        "year": "2015-"
      },
      "Wagon R": {
        "year": "2012-"
      },
      "Wagon R+": {
        "year": "2000-2006"
      },
      "XL6": {
        "year": "2019-"
      },
      "XL7": {
        "year": "2020-"
      }
    }
  },
  "Tesla": {
    "model": {
      "Model 3": {
        "year": "2017-"
      },
      "Model S": {
        "year": "2012-"
      },
      "Model X": {
        "year": "2015-"
      },
      "Model Y": {
        "year": "2019-"
      }
    }
  },
  "Toyota": {
    "model": {
      "4Runner": {
        "year": "1995-"
      },
      "Alphard": {
        "year": "2002-"
      },
      "Auris": {
        "year": "2007-"
      },
      "Avalon": {
        "year": "2000-"
      },
      "Avensis": {
        "year": "1997-2018"
      },
      "Avensis Verso": {
        "year": "2001-2010"
      },
      "Aygo": {
        "year": "2005-"
      },
      "Aygo X": {
        "year": "2021-"
      },
      "C+pod": {
        "year": "2020-"
      },
      "C-HR": {
        "year": "2016-"
      },
      "Caldina": {
        "year": "2002-2007"
      },
      "Camry": {
        "year": "1996-"
      },
      "Celica": {
        "year": "1999-2006"
      },
      "Corolla": {
        "year": "1995-"
      },
      "Corolla Cross": {
        "year": "2020-"
      },
      "Corolla Verso": {
        "year": "2002-2009"
      },
      "FJ Cruiser": {
        "year": "2007-2016"
      },
      "Fortuner": {
        "year": "2004-"
      },
      "GT 86": {
        "year": "2012-"
      },
      "Hiace": {
        "year": "2006-"
      },
      "Highlander": {
        "year": "2000-"
      },
      "Hilux": {
        "year": "2005-"
      },
      "iQ": {
        "year": "2008-2016"
      },
      "ist": {
        "year": "2002-2016"
      },
      "Land Cruiser": {
        "year": "1990-"
      },
      "Land Cruiser Prado": {
        "year": "2002-"
      },
      "Mark II": {
        "year": "2000-2004"
      },
      "Mirai": {
        "year": "2014-"
      },
      "MR2": {
        "year": "1999-2007"
      },
      "Picnic": {
        "year": "1995-2001"
      },
      "Previa": {
        "year": "2000-"
      },
      "Prius": {
        "year": "2003-"
      },
      "Prius Prime": {
        "year": "2017-"
      },
      "RAV4": {
        "year": "1994-"
      },
      "Sequoia": {
        "year": "2001-"
      },
      "Sienna": {
        "year": "2002-"
      },
      "Supra": {
        "year": "2019-"
      },
      "Tacoma": {
        "year": "2005-"
      },
      "Tundra": {
        "year": "1999-"
      },
      "Venza": {
        "year": "2008-2016"
      },
      "Verso": {
        "year": "2009-"
      },
      "Vitz": {
        "year": "2005-"
      },
      "Yaris": {
        "year": "1999-"
      },
      "Yaris Verso": {
        "year": "1999-2005"
      }
    }
  },
  "UAZ": {
    "model": {
      "Pickup": {
        "year": "2008-"
      },
      "Патриот": {
        "year": "2005-"
      },
      "Хантер": {
        "year": "2003-"
      }
    }
  },
  "VAZ": {
    "model": {
      "2101-2107": {
        "year": "1976-2012"
      },
      "2108, 2109, 21099": {
        "year": "1984-2004"
      },
      "2110, 2111, 2112": {
        "year": "1996-2014"
      },
      "2113, 2114, 2115": {
        "year": "1997-2015"
      },
      "4x4 Urban": {
        "year": "2014-"
      },
      "Granta": {
        "year": "2011-"
      },
      "Granta Cross": {
        "year": "2019-"
      },
      "Largus": {
        "year": "2012-"
      },
      "Largus Cross": {
        "year": "2015-"
      },
      "Niva Legend": {
        "year": "2021-"
      },
      "Niva Travel": {
        "year": "2021-"
      },
      "Vesta Cross": {
        "year": "2018-"
      },
      "Vesta Sport": {
        "year": "2018-"
      },
      "Vesta SW": {
        "year": "2017-"
      },
      "XRay": {
        "year": "2016-"
      },
      "XRay Cross": {
        "year": "2018-"
      },
      "Веста": {
        "year": "2015-"
      },
      "Калина": {
        "year": "2004-2013"
      },
      "Нива 4X4": {
        "year": "1977-"
      },
      "Ока": {
        "year": "1988-2008"
      },
      "Приора": {
        "year": "2007-2018"
      }
    }
  },
  "Volkswagen": {
    "model": {
      "Amarok": {
        "year": "2010-"
      },
      "Arteon": {
        "year": "2017-"
      },
      "Beetle": {
        "year": "2011-2019"
      },
      "Bora": {
        "year": "1998-2005"
      },
      "Caddy": {
        "year": "2004-"
      },
      "CC": {
        "year": "2012-"
      },
      "Crafter": {
        "year": "2008-"
      },
      "CrossGolf": {
        "year": "2007-2009"
      },
      "CrossPolo": {
        "year": "2006-2009"
      },
      "CrossTouran": {
        "year": "2007-2015"
      },
      "Eos": {
        "year": "2006-2015"
      },
      "Fox": {
        "year": "2005-2011"
      },
      "Golf": {
        "year": "1991-"
      },
      "ID.3": {
        "year": "2020-"
      },
      "ID.4": {
        "year": "2020-"
      },
      "ID.4 X": {
        "year": "2021-"
      },
      "Jetta": {
        "year": "2005-"
      },
      "Lupo": {
        "year": "1998-2005"
      },
      "Multivan": {
        "year": "2003-"
      },
      "New Beetle": {
        "year": "1998-2007"
      },
      "Passat": {
        "year": "2000-"
      },
      "Passat CC": {
        "year": "2008-2012"
      },
      "Phaeton": {
        "year": "2002-2016"
      },
      "Pointer": {
        "year": "1993-1997"
      },
      "Polo": {
        "year": "2001-"
      },
      "Routan": {
        "year": "2008-2014"
      },
      "Scirocco": {
        "year": "2008-2017"
      },
      "Sharan": {
        "year": "1995-"
      },
      "T-Roc": {
        "year": "2017-"
      },
      "Taos": {
        "year": "2020-"
      },
      "Teramont": {
        "year": "2017-"
      },
      "Teramont X": {
        "year": "2019-"
      },
      "Tiguan": {
        "year": "2007-"
      },
      "Tiguan X": {
        "year": "2020-"
      },
      "Touareg": {
        "year": "2002-"
      },
      "Touran": {
        "year": "2003-"
      },
      "Transporter": {
        "year": "1990-"
      },
      "Up": {
        "year": "2011-"
      }
    }
  },
  "Volvo": {
    "model": {
      "C30": {
        "year": "2007-2013"
      },
      "C40": {
        "year": "2021-"
      },
      "C70": {
        "year": "2006-2013"
      },
      "C70 Convertible": {
        "year": "1997-2006"
      },
      "C70 Coupe": {
        "year": "1996-2007"
      },
      "S40": {
        "year": "1995-2012"
      },
      "S60": {
        "year": "2000-"
      },
      "S70": {
        "year": "1996-2000"
      },
      "S80": {
        "year": "1998-2016"
      },
      "S90": {
        "year": "1997-"
      },
      "V40": {
        "year": "1995-"
      },
      "V50": {
        "year": "2004-2012"
      },
      "V60": {
        "year": "2011-"
      },
      "V70": {
        "year": "1996-"
      },
      "V90": {
        "year": "1997-"
      },
      "XC40": {
        "year": "2017-"
      },
      "XC60": {
        "year": "2008-"
      },
      "XC70": {
        "year": "2001-2016"
      },
      "XC90": {
        "year": "2002-"
      }
    }
  }
}