import React from "react";

const Title = ({
  title, 
  text,
  crumb
}) => {

  return (
    <>
      <section className="breadcrumb-area d-flex align-items-center" style={{backgroundImage: `url(${require('../images/bg/header-bg.png')})`}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title mb-30">
                  <h3 className="mb-4">{title}</h3>
                  <p style={{color: '#08C802'}} >{text}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 text-right">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Главная</a></li>
                    <li className="breadcrumb-item"><a style={{color: '#08C802'}}  href="/contacts">{crumb ? crumb : "Контакты"}</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Title;
