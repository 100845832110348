import React from 'react';

const NoRoute = () => {

    return(
      <>
        <div className="iphone">
            <div className="text-center">
            <img src={require('../images/logo.png')} width={'250'} alt="" />
            </div>
            <hr/>
            <img src={require('../images/404.jpg')} className="mb-2" width={'100%'} alt="404" />
            <div className="alert alert-warning text-center">
              <h4>Страница не найдена ⚠️</h4>
              <hr />
              Oopps!! The page you were looking for doesn't exist.
            </div>
        </div>
      </>
    )
};

export default NoRoute;