import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sendNotification } from "../helpers/notification";
import { Cars } from "../helpers/data";

import { PageContext } from "../context/page-context";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//date picker
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'moment/locale/ru';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


import { isEmpty, isUndefined } from "lodash";

const steps = [
  { title: "Данные автомобиля", label: "Шаг 1" },
  { title: "Водители", label: "Шаг 2" },
  { title: "Данные собственника", label: "Шаг 3 - Финальный" },
];

const Osago = (props) => {
  const PageCunsumer = useContext(PageContext);

  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = React.useState({});

  const stepOneFields = [
    { key: 'vehicleCategory' },
    { key: 'carNumber' },
    { key: 'brand' },
    { key: 'model' },
    { key: 'year' },
    { key: 'power' },
    { key: 'vin' },
    { key: 'document' },
    { key: 'pts' },
    { key: 'documentObtainingDate' },
  ];

  const stepTwoFields = [
    { key: 'driver_lastname' },
    { key: 'driver_name' },
    { key: 'driver_thridname' },
    { key: 'driver_birthday' },
    { key: 'driver_license' },
    { key: 'driver_first_license_date' },
    { key: 'driver_first_exp' },
  ];

  const stepThridFields = [
    { key: 'owner_lastname' },
    { key: 'owner_name' },
    { key: 'owner_thridname' },
    { key: 'owner_birthday' },
    { key: 'owner_passport' },
    { key: 'owner_passport_date' },
    { key: 'owner_addres' },
    { key: 'owner_email' },
    { key: 'owner_phone' },
    { key: 'owner_passport_state' },
    { key: 'owner_passport_state_code' },
  ];

  function isFieldEmptyOrUndefined(fieldValue) {
    return isEmpty(fieldValue) || isUndefined(fieldValue);
  }

  const submitStep1 = (e) => {
    e.preventDefault();

    if (activeStep === 0) {
      const newErrorState = {};

      stepOneFields.forEach(field => {
        if (isFieldEmptyOrUndefined(PageCunsumer[field.key])) {
          newErrorState[field.key] = true;
        }
      });
  
      if (Object.keys(newErrorState).length > 0) {
        setError(newErrorState);
        sendNotification("warning", "Заполните обязательные поля", "Вы не заполнили все обязательные поля на шаге №1, введите информацию в поля.");
        return;
      }
  
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const submitStep2 = (e) => {
    e.preventDefault();

    if (activeStep === 1) {
      const newErrorState = {};

      stepTwoFields.forEach(field => {
        if (isFieldEmptyOrUndefined(PageCunsumer[field.key])) {
          newErrorState[field.key] = true;
        }
      });
  
      if (Object.keys(newErrorState).length > 0) {
        setError(newErrorState);
        sendNotification("warning", "Заполните обязательные поля", "Вы не заполнили все обязательные поля на шаге №2, введите информацию в поля.");
        return;
      }
  
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const submitStep3 = async (e) => {
    e.preventDefault();
    window.ym(97401187,'reachGoal','osagoform');

    if (activeStep === 2) {
      const newErrorState = {};

      stepThridFields.forEach(field => {
        if (isFieldEmptyOrUndefined(PageCunsumer[field.key])) {
          newErrorState[field.key] = true;
        }
      });
  
      if (Object.keys(newErrorState).length > 0) {
        setError(newErrorState);
        sendNotification("warning", "Заполните обязательные поля", "Вы не заполнили все обязательные поля на шаге №3, введите информацию в поля.");
        return;
      }

      await PageCunsumer.peshOrder();
  
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    if (error[event.target.name]) {
      setError(prevState => ({ ...prevState, [event.target.name]: false }))
    }

    if (event.target.name === 'vin') {
      PageCunsumer.updateState(event.target.name, event.target.value.toUpperCase());
      return;
    }

    if (event.target.name === 'driver_eql_owner') {
      if (event.target.checked === true) {
        PageCunsumer.owner_eql_driver();
      } else {
        PageCunsumer.clear_owner_eql_driver();
      }
      return;
    }

    PageCunsumer.updateState(event.target.name, event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Расчет ОСАГО - страховое агентство ГосАвтоПолис </title>
        <meta
          name="description"
          content="Рассчитайте стоимость ОСАГО онлайн с нашим калькулятором. Получите быструю оценку страховки для вашего автомобиля."
        />
      </Helmet>

      <Header />

      <section
        className="breadcrumb-area d-flex align-items-center"
        style={{
          backgroundImage: `url(${require("../images/bg/header-bg.png")})`,
          minHeight: "400px",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title animated fadeInUp">
                  <h3 className="mb-4">Расчет стоимости полиса ОСАГО 📃</h3>
                  <p style={{ color: "#08C802" }}>
                    <strong>Заполните анкету ниже 👉</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 text-right">
              <div className="breadcrumb-wrap animated fadeInUp">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Главная</a>
                    </li>
                    <li className="breadcrumb-item active">Расчет ОСАГО</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-50 pb-50 p-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row justify-content-center mb-5">
                <div className="col-md-7 col-12">
                  <Alert severity="warning">
                    <AlertTitle><strong>Внимание!</strong></AlertTitle>
                    1. Указывайте данные строго в <strong>строго в соответствии с документами</strong>. <br />
                    2. После расчета стоимости полиса, <strong>Вам перезвонят.</strong> <br />
                    3. После оплаты, полис оформляется в течение 5 минут. <br />
                  </Alert>
                </div>
              </div>
              {/* <div className="section-title text-center mb-3 animated fadeInUp">
                <h5>
                  <span className="line2">
                    {" "}
                    <img
                      src={require("../images/bg/circle_right.png")}
                      alt="circle_right"
                    />
                  </span>{" "}
                  Заполните анкету ниже{" "}
                </h5>
              </div>
              <hr /> */}

              <Box sx={{ width: "100%" }} className="animated fadeInUp">
                <Stepper activeStep={activeStep} className="mobile-overflow">
                  {steps.map((item, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    labelProps.optional = (
                      <Typography variant="caption">{item.label}</Typography>
                    );

                    return (
                      <Step key={index} {...stepProps}>
                        <StepLabel {...labelProps}>{item.title}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <>
                    <div className="row justify-content-center mt-50 animated fadeInUp">
                      <div className="col-md-8 text-center">
                        <div className="alert alert-success-2 p-3 pt-4 p-md-5">
                          <i className="far fa-check-circle mb-4" style={{ fontSize: '60px' }}></i>
                          <h3 className="text-light">Заявка успешно оформлена</h3>
                          <p>Спасибо за вашу заявку! Мы уже работаем над вашим запросом. Наши опытные операторы подберут самую выгодную цену специально для вас и свяжутся с вами в течение 30-60 минут.</p>
                          <hr />
                          <Link to="/" className="btn btn-light p-3">
                           Вернуться на главную 👉
                          </Link>
                          <Link to="/contacts" className="btn btn-warning ml-md-5 p-3 mt-4 mt-md-0">
                           Наши контакты ℹ️
                          </Link>
                        </div>
                      </div>
                    </div>
                    
                  </>
                ) : (
                  <React.Fragment>
                    {activeStep === 0 && (
                      <form className="anketa-osago mx-auto animated fadeInLeft" onSubmit={submitStep1}>

                        <h4 className="text-center mt-60 mb-5">Шаг 1: Данные автомобиля</h4>   
                        {/* категория, гос Номер */}
                        <div className="row">
                          <div className="col-md-4 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <InputLabel id="vehicleCategory">
                                Категория ТС
                              </InputLabel>
                              <Select
                                id="vehicleCategory"
                                name="vehicleCategory"
                                value={
                                  PageCunsumer.vehicleCategory
                                    ? PageCunsumer.vehicleCategory
                                    : ""
                                }
                                label="Категория ТС"
                                error={ error.vehicleCategory }
                                placeholder="Категория ТС"
                                onChange={handleChange}
                              >
                                <MenuItem value={"A — мотоцикл"}>
                                  A — мотоцикл
                                </MenuItem>
                                <MenuItem value={"B — автомобиль"}>
                                  B — автомобиль
                                </MenuItem>
                              </Select>
                              { error.vehicleCategory && 
                                <FormHelperText className="text-danger">Выбирите категорию ТС.</FormHelperText>
                              }
                            </FormControl>
                          </div>
                          <div className="col-md-4 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <TextField
                                id="carNumber"
                                name="carNumber"
                                value={ PageCunsumer.carNumber ? PageCunsumer.carNumber : "" }
                                label="Гос. номер"
                                variant="outlined"
                                onChange={handleChange}
                                error={ error.carNumber }
                                helperText={error.carNumber && "Введите Гос. номер"}
                              />
                            </FormControl>
                          </div>
                        </div>

                        {/* Марка модель год */}
                        <div className="row mt-md-4">
                          <div className="col-md-4 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <Autocomplete
                                disablePortal
                                id="brand"
                                options={Object.keys(Cars)}
                                inputValue={ PageCunsumer.brand && PageCunsumer.brand }
                                onChange={(event, newValue) => {
                                  handleChange({ target: {name: 'year', value: ""}});
                                  handleChange({ target: {name: 'model', value: ""}});
                                  handleChange({ target: {name: 'brand', value: newValue}});
                                }}
                                renderInput={(params) => <TextField {...params} error={ error.brand } helperText={error.brand && "Выберите марку авто"} label="Марка авто" />}
                              />
                            </FormControl>
                          </div>
                          <div className="col-md-4 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <Autocomplete
                                disablePortal
                                id="model"
                                options={ isEmpty(PageCunsumer.brand) ? ["Сначала выберите марку авто"] : Object.keys(Cars[PageCunsumer.brand].model) }
                                getOptionDisabled={(option) =>
                                  option === 'Сначала выберите марку авто'
                                }
                                onChange={(event, newValue) => {
                                  handleChange({ target: {name: 'model', value: newValue}});
                                }}
                                inputValue={ PageCunsumer.model && PageCunsumer.model }
                                renderInput={(params) => <TextField {...params} variant="outlined" error={ error.model } helperText={error.model && "Выберите модель авто"} name="model" label="Модель авто" />}
                              />
                            </FormControl>
                          </div>
                          <div className="col-md-4 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <Autocomplete
                                freeSolo
                                id="year"
                                options={ isEmpty(PageCunsumer.model) ? ["Сначала выберите модель авто"] : [Cars[PageCunsumer.brand].model[PageCunsumer.model].year] }
                                getOptionDisabled={(option) =>
                                  option === 'Сначала выберите модель авто'
                                }
                                onChange={(event, newValue) => {
                                  handleChange({ target: {name: 'year', value: newValue}});
                                }}
                                inputValue={ PageCunsumer.year ? PageCunsumer.year : "" }
                                onInputChange={(event, newInputValue) => {
                                  handleChange({ target: {name: 'year', value: newInputValue}});
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" error={ error.year } helperText={error.year && "Введите год выпуска"} name="model" label="Год выпуска" />}
                              />
                            </FormControl>
                          </div>
                        </div>

                        {/* Мощность */}
                        <div className="row mt-md-4">
                          <div className="col-md-4 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <TextField
                                id="power"
                                name="power"
                                value={ PageCunsumer.power ? PageCunsumer.power : "" }
                                label="Мощность двигателя"
                                variant="outlined"
                                onChange={handleChange}
                                error={ error.power }
                                helperText={error.power && "Введите мощность двигателя"}
                              />
                            </FormControl>
                          </div>
                          <div className="col-md-8 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <TextField
                                id="vin"
                                name="vin"
                                value={ PageCunsumer.vin ? PageCunsumer.vin : "" }
                                label="Номер VIN"
                                variant="outlined"
                                onChange={handleChange}
                                error={ error.vin }
                                helperText={error.vin && "Введите vin номер"}
                              />
                              </FormControl>
                          </div>
                        </div>

                        <div className="alert alert-secondary mt-4 p-3 p-md-5" style={{ backgroundColor: "#E6F9FF" }} role="alert">
                          <p>Если вы только что купили автомобиль и будете ставить его на учёт со сменой номера — указывайте обязательно документ — ПТС. После получения нового рег. знака вы сможете вписать его в полис. Если замена номера не планируется — можете указать любой тип документа</p>
                          {/* Марка модель год */}
                          <div className="row mt-4">
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth className="mb-4">
                                <InputLabel id="document">
                                  Тип документа на авто
                                </InputLabel>
                                <Select
                                  id="document"
                                  name="document"
                                  value={ PageCunsumer.document ? PageCunsumer.document : "" }
                                  label="Тип документа на авто"
                                  onChange={handleChange}
                                  error={ error.document }
                                >
                                  <MenuItem value={"СТС"}>
                                    СТС (Свидетельство)
                                  </MenuItem>
                                  <MenuItem value={"ПТС"}>
                                    ПТС (Паспорт ТС)
                                  </MenuItem>
                                  <MenuItem value={"ЭПТС"}>
                                    ЭПТС (электронный ПТС)
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <TextField
                                  id="pts"
                                  name="pts"
                                  value={ PageCunsumer.pts ? PageCunsumer.pts : "" }
                                  label="Номер документа"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.pts }
                                  helperText={error.pts && "Введите номер документа"}
                                />
                              </FormControl>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterMoment}>
                                  <DatePicker 
                                    label="Дата выдачи документа"
                                    disableFutur={true}
                                    value={ PageCunsumer.documentObtainingDate && PageCunsumer.documentObtainingDate }
                                    onChange={(newValue) => {
                                      handleChange({ target: {name: 'documentObtainingDate', value: newValue}});
                                    }}
                                    id="documentObtainingDate" 
                                    name="documentObtainingDate"
                                    slotProps={{ textField: { variant: 'outlined', error: error.documentObtainingDate, helperText: error.documentObtainingDate && "Введите дату выдачи" } }}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </div>
                          </div>
                        </div>

                        <Box sx={{ display: "flex", justifyContent: 'center', pt: 2 }}>
                          <button
                            className="btn btn-secondary mr-3 mr-md-5"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            👈 Назад
                          </button>

                          <button type="submit" className="btn ss-btn" >
                            {activeStep === steps.length - 1 ? "Finish" : "Дальше"}
                            <i className="fal fa-arrow-right"></i>
                          </button>
                        </Box>
                      </form>
                    )}

                    {/* шаг 2, Водители */}
                    {activeStep === 1 && (
                      <form className="anketa-osago mx-auto animated fadeInLeft" onSubmit={submitStep2}>
                        <h4 className="text-center mt-60 mb-5">Шаг 2: Водитель</h4>      
                        {/* Водитель ФИО */}
                        <div className="row mb-md-4">
                          <div className="col-md-4 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <TextField
                                id="driver_lastname"
                                name="driver_lastname"
                                value={ PageCunsumer.driver_lastname ? PageCunsumer.driver_lastname : "" }
                                label="Фамилия"
                                variant="outlined"
                                onChange={handleChange}
                                error={ error.driver_lastname }
                                helperText={error.driver_lastname && "Введите фамилию"}
                              />
                            </FormControl>
                          </div>
                          <div className="col-md-4 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <TextField
                                id="driver_name"
                                name="driver_name"
                                value={ PageCunsumer.driver_name ? PageCunsumer.driver_name : "" }
                                label="Имя"
                                variant="outlined"
                                onChange={handleChange}
                                error={ error.driver_name }
                                helperText={error.driver_name && "Введите имя"}
                              />
                            </FormControl>
                          </div>
                          <div className="col-md-4 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <TextField
                                id="driver_thridname"
                                name="driver_thridname"
                                value={ PageCunsumer.driver_thridname ? PageCunsumer.driver_thridname : "" }
                                label="Отчество"
                                variant="outlined"
                                onChange={handleChange}
                                error={ error.driver_thridname }
                                helperText={error.driver_thridname && "Введите отчество"}
                              />
                            </FormControl>
                          </div>
                        </div>

                        {/* Дата рождения + паспорт */}
                        <div className="row mb-md-4">
                          <div className="col-md-4 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterMoment}>
                                <DatePicker 
                                  label="Дата рождения"
                                  disableFutur={true}
                                  value={ PageCunsumer.driver_birthday && PageCunsumer.driver_birthday }
                                  onChange={(newValue) => {
                                    handleChange({ target: {name: 'driver_birthday', value: newValue}});
                                  }}
                                  id="driver_birthday" 
                                  name="driver_birthday"
                                  slotProps={{ textField: { variant: 'outlined', error: error.driver_birthday, helperText: error.driver_birthday && "Введите дату рождения" } }}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </div>
                          <div className="col-md-8 mb-3 mb-md-0">
                            <FormControl fullWidth>
                              <TextField
                                id="driver_license"
                                name="driver_license"
                                value={ PageCunsumer.driver_license ? PageCunsumer.driver_license : "" }
                                label="Серия и номер ВУ"
                                variant="outlined"
                                onChange={handleChange}
                                error={ error.driver_license }
                                helperText={error.driver_license && "Введите cерию и номер ВУ"}
                              />
                            </FormControl>
                          </div>
                        </div>

                        {/* Дата выдачи первых */}
                        <div className="row">
                          <div className="col-md-4">
                            <FormControl fullWidth>
                              <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterMoment}>
                                <DatePicker 
                                  label="Дата выдачи прав"
                                  disableFutur={true}
                                  value={ PageCunsumer.driver_first_license_date && PageCunsumer.driver_first_license_date }
                                  onChange={(newValue) => {
                                    handleChange({ target: {name: 'driver_first_license_date', value: newValue}});
                                  }}
                                  id="driver_first_license_date" 
                                  name="driver_first_license_date"
                                  slotProps={{ textField: { variant: 'outlined', error: error.driver_first_license_date, helperText: error.driver_first_license_date && "Введите дату выдачи прав" } }}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </div>
                          <div className="col-md-4">
                            <FormControl fullWidth>
                              <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterMoment}>
                                <DatePicker 
                                  label="Дата начала стажа"
                                  disableFutur={true}
                                  value={ PageCunsumer.driver_first_exp && PageCunsumer.driver_first_exp }
                                  onChange={(newValue) => {
                                    handleChange({ target: {name: 'driver_first_exp', value: newValue}});
                                  }}
                                  id="driver_first_exp" 
                                  name="driver_first_exp"
                                  slotProps={{ textField: { variant: 'outlined', error: error.driver_first_exp, helperText: error.driver_first_exp && "Введите дату начала водительського стажа" } }}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </div>
                        </div>

                        <Box sx={{ display: "flex", justifyContent: 'center', pt: 2 }}>
                          <button
                            className="btn btn-secondary mr-3 mr-md-5"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            👈 Назад
                          </button>

                          <button type="submit" className="btn ss-btn" >
                            {activeStep === steps.length - 1 ? "Finish" : "Дальше"}
                            <i className="fal fa-arrow-right"></i>
                          </button>
                        </Box>
                      </form>
                    )}

                    {/* шаг 3, владелец */}
                    {activeStep === 2 && (
                      <>
                        <form className="anketa-osago mx-auto animated fadeInLeft" onSubmit={submitStep3}>  
                          <h4 className="text-center mt-60 mb-4">Данные собственника</h4>                      
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <div className="alert alert-blue pt-3 pb-1" role="alert">
                                <FormControl fullWidth>
                                  <FormControlLabel 
                                    control={
                                      <Checkbox 
                                        onChange={handleChange}
                                        checked={ PageCunsumer.driver_eql_owner }
                                        name="driver_eql_owner"
                                      />
                                    } 
                                    className="text-dark"
                                    sx={{
                                      justifyContent: 'center'
                                    }}
                                    label="Страхователь совпадает с собственником" 
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          
                          {/* владелец ФИО */}
                          <div className="row mb-md-4">
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <TextField
                                  id="owner_lastname"
                                  name="owner_lastname"
                                  value={ PageCunsumer.owner_lastname ? PageCunsumer.owner_lastname : "" }
                                  label="Фамилия"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.owner_lastname }
                                  helperText={error.owner_lastname && "Введите фамилию"}
                                />
                              </FormControl>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <TextField
                                  id="owner_name"
                                  name="owner_name"
                                  value={ PageCunsumer.owner_name ? PageCunsumer.owner_name : "" }
                                  label="Имя"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.owner_name }
                                  helperText={error.owner_name && "Введите имя"}
                                />
                              </FormControl>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <TextField
                                  id="owner_thridname"
                                  name="owner_thridname"
                                  value={ PageCunsumer.owner_thridname ? PageCunsumer.owner_thridname : "" }
                                  label="Отчество"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.owner_thridname }
                                  helperText={error.owner_thridname && "Введите отчество"}
                                />
                              </FormControl>
                            </div>
                          </div>

                          {/* Дата рождения + паспорт */}
                          <div className="row mb-md-4">
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterMoment}>
                                  <DatePicker 
                                    label="Дата рождения"
                                    disableFutur={true}
                                    value={ PageCunsumer.owner_birthday && PageCunsumer.owner_birthday }
                                    onChange={(newValue) => {
                                      handleChange({ target: {name: 'owner_birthday', value: newValue}});
                                    }}
                                    id="owner_birthday" 
                                    name="owner_birthday"
                                    slotProps={{ textField: { variant: 'outlined', error: error.owner_birthday, helperText: error.owner_birthday && "Введите дату рождения" } }}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <TextField
                                  id="owner_passport"
                                  name="owner_passport"
                                  value={ PageCunsumer.owner_passport ? PageCunsumer.owner_passport : "" }
                                  label="Серия и номер паспорта"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.owner_passport }
                                  helperText={error.owner_passport && "Введите cерию и номер паспорта"}
                                />
                              </FormControl>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterMoment}>
                                  <DatePicker 
                                    label="Дата выдачи"
                                    disableFutur={true}
                                    value={ PageCunsumer.owner_passport_date && PageCunsumer.owner_passport_date }
                                    onChange={(newValue) => {
                                      handleChange({ target: {name: 'owner_passport_date', value: newValue}});
                                    }}
                                    id="owner_passport_date" 
                                    name="owner_passport_date"
                                    slotProps={{ textField: { variant: 'outlined', error: error.owner_passport_date, helperText: error.owner_passport_date && "Введите дату выдачи паспорта" } }}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </div>
                          </div>

                          <div className="row mb-md-4">
                            <div className="col-md-8 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <TextField
                                  id="owner_passport_state"
                                  name="owner_passport_state"
                                  value={ PageCunsumer.owner_passport_state ? PageCunsumer.owner_passport_state : "" }
                                  label="Кем выдан паспорт"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.owner_passport_state }
                                  helperText={error.owner_passport_state ? "Заполниет поле кто выдал пасспорт" : "пример: ГУ МВД РОССИИ ПО РОСТОВСКОЙ ОБЛАСТИ"}
                                />
                              </FormControl>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <TextField
                                  id="owner_passport_state_code"
                                  name="owner_passport_state_code"
                                  value={ PageCunsumer.owner_passport_state_code ? PageCunsumer.owner_passport_state_code : "" }
                                  label="Код подразделения"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.owner_passport_state_code }
                                  helperText={error.owner_passport_state_code ? "Введите код подразделения" : "пример: 610-055"}
                                />
                              </FormControl>
                            </div>
                          </div>
                           {/* Адрес владельца */}
                          <div className="row mb-md-4">
                            <div className="col-md-8 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <TextField
                                  id="owner_addres"
                                  name="owner_addres"
                                  value={ PageCunsumer.owner_addres ? PageCunsumer.owner_addres : "" }
                                  label="Адрес регистрации (город, улица, дом)"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.owner_addres }
                                  helperText={error.owner_addres && "Введите адрес регистрации"}
                                />
                              </FormControl>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <TextField
                                  id="owner_house_number"
                                  name="owner_house_number"
                                  value={ PageCunsumer.owner_house_number ? PageCunsumer.owner_house_number : "" }
                                  label="Квартира"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.owner_house_number }
                                  helperText={error.owner_house_number && "Введите номер квартиры"}
                                />
                              </FormControl>
                            </div>
                          </div>

                          <h4 className="text-center mt-40 mb-4">Контактные данные</h4>
                          <p className="text-muted text-center">
                            Телефон нужен для расчета ОСАГО, и подтверждения заказа, а на указаную почту мы вышлем готовый полис.
                          </p>                      
                          <div className="row mb-3">
                            <div className="col-md-6 mb-3 mb-md-0">
                              <FormControl fullWidth>
                                <TextField
                                  id="owner_email"
                                  name="owner_email"
                                  value={ PageCunsumer.owner_email ? PageCunsumer.owner_email : "" }
                                  label="Электронная почта"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.owner_email }
                                  helperText={error.owner_email && "Введите почту"}
                                />
                              </FormControl>
                            </div>
                            <div className="col-md-6">
                              <FormControl fullWidth>
                                <TextField
                                  id="owner_phone"
                                  name="owner_phone"
                                  value={ PageCunsumer.owner_phone ? PageCunsumer.owner_phone : "" }
                                  label="Номер телефона"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={ error.owner_phone }
                                  helperText={error.owner_phone && "Введите номер телефона"}
                                />
                              </FormControl>
                            </div>
                          </div>

                          <Box sx={{ display: "flex", justifyContent: 'center', pt: 2 }}>
                            <button type="submit" className="btn ss-btn" >
                              {activeStep === steps.length - 1 ? "Отправить запрос" : "Дальше"}
                              <i className="fal fa-arrow-right"></i>
                            </button>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: 'center', pt: 2 }}>
                            <button
                                className="btn btn-secondary"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                              >
                                👈 Назад
                              </button>
                          </Box>
                        </form>
                      </>
                    )}
                    
                  </React.Fragment>
                )}
              </Box>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Osago;
