import React from "react";
import { Helmet } from 'react-helmet';

import Header from "../../components/Header";
import Title from "../../components/Title";
import Footer from "../../components/Footer";

const Reliable = (props) => {
  return (
    <>
      <Helmet>
        <title>Как проверить, надежно ли страховое агентство? - Госавтополис</title>
        <meta name="description" content="Как проверить надежность страхового агента или страхового брокера? Как выявить мошенников? Критерии выбора страхового агентства." />
      </Helmet>

      <Header />

      <Title
        title='Как узнать, надежно ли страховое агентство?'
        text='Доверие и защита в каждом полисе.'
        crumb='Информация'
      />

      <section id="about" className="about-area about-p pt-50 pb-50 p-relative">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <h4 className="text-center mb-4">Закон об ОСАГО и правила ОСАГО</h4> */}
              <div className="info-page-text">
                <p>Существует четыре простых способа, позволяющих проверить надежность и профессионализм страхового агента, с которым Вы предполагаете заключить сделку.</p>
                <ol>
                  <li>Предпочтительно иметь дело со страховыми агентами или страховыми брокерами, которые являются юридическими лицами или ИП, что обеспечит Вам определенные гарантии, что данный агент или брокер прошел проверку хотя бы нескольких служб безопасности, а именно:</li>
                </ol>

                <ul>
                  <li>банка при открытии счета;</li>
                  <li>страховых компаний при оформлении договоров.</li>
                </ul>

                <ol start="2">
                  <li>Не менее значимый способ проверки – оплата страхового полиса. Профессиональные ответственные страховые брокеры и агенты всегда предлагают три вида взаиморасчетов:</li>
                </ol>

                <ul>
                  <li>наличными при покупке полиса;</li>
                  <li>безналичным переводом на банковский счет страхового брокера/агента или самой страховой компании;</li>
                  <li>оплатить рассрочкой на условиях страховщика.</li>
                </ul>

                <p>Когда агент, предлагающий оформить полис, использует единственный вариант оплаты, подумайте, стоит ли с ним иметь дело.</p>

                <p><strong>Запомните! В авторитетном страховом агентстве не должна быть намного ниже, чем в страховой компании.</strong> Разумеется, агент может предложить скидку из своего личного вознаграждения, однако же, она не может выражаться в десятках процентов!</p>

                <ol start="3">
                  <li>В некоторых случаях клиенты, желая отремонтировать незастрахованное транспортное средство за деньги страховой компании, сговариваются со страховым агентом. То есть при заключении договора предлагают ему не фиксировать видимые повреждения. Добропорядочный страховой агент никогда не согласится с подлогом и мошеннической схемой, несмотря на уговоры.</li>
                  <li>Возьмите у агента реквизиты своего будущего страхового полиса, что он вам приготовил, и уточните его достоверность в страховой компании, выяснив:</li>
                </ol>

                <ul>
                  <li>имеет ли отношение страховой полис к данной компании;</li>
                  <li>на кого выдан страховой полис (компания, агент, брокер).</li>
                </ul>

                <p>Знайте, эти простые правила смогут защитить Вас от мошенничества в страховании и избавить от сомнений по поводу надежности выбора!</p>

                <p>Всегда нужно помнить, что страховой агент не служит курьером, доставляющим клиентам полис. Это профессионал, практикующий на рынке страховых услуг, специалист по страхованию и человек, заинтересованный в качестве услуг для своих клиентов.</p>

                <p><strong>Прежде всего, страховой агент соблюдает интересы клиентов, оказывает помощь в определении убытков, дает консультации по профессиональным вопросам.</strong></p>

                <p><strong>Агент всегда принимает сторону клиента в границах своей служебной деятельности.</strong></p>

                <p>Если ваш страховой агент не соответствует данным критериям, подумайте, возможно, переплата за доставку полиса без малейшей выгоды для Вас – это лишнее?</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Reliable;