import React from "react";
import { Helmet } from 'react-helmet';

import Header from "../components/Header";
import Title from "../components/Title";
import Footer from "../components/Footer";

const Property = (props) => {

  return (
    <>
     <Helmet>
        <title>Госавтополис - страховое агентство </title>
        <meta name="description" content="Страхование ипотеки для защиты вашей собственности. Обеспечьте ответственность за платежи в случае непредвиденных обстоятельств." />
      </Helmet>

      <Header />

      <Title title='Ипотечное страхование квартиры' text='Доверие и защита в каждом полисе.'/>

      <section id="about" className="about-area about-p pt-50 pb-50 p-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 w-100 pr-5 pt-5 p-sm-none">
              <div className="s-about-img p-relative">
                <img className="images-radius" decoding="async" src={require('../images/home-insurance.jpg')} alt="img"/>        
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 mt-5 pt-5 pl-5 p-sm-none">
              <div className="about-content s-about-content">
                <div className="about-title second-atitle pb-40">  
                  <h5 style={{fontWeight: '500'}}>Страховые случаи по ипотечному полису</h5>
                  <h4>Онлайн страхование квартиры по ипотеке способно защитить ваше жилье от страховых случаев: </h4>  
                  <ul className="mt-5">
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>возгорание</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>подтопление</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>взрыв газосодержащих или отопительных приборов</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>авария инженерных систем</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>повреждение стеклянных конструкций</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>кража</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contacts" className="make-call-area make-area p-relative">
        <div className="container">
          <div className="row pt-110 p-sm-none">
            <div className="col-lg-8 col-md-12">
              <div className="section-title cta-title"> 
                <h5 style={{fontWeight: '500'}}>ГосАвтоПолис</h5> 
                <h4 className="text-white" style={{fontSize: '30px'}}>Доступный тариф и выгодные условия!</h4>
                <p>Для получения индивидуального расчета свяжитесь с нашим менеджером.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 pt-3">
              <div className="call-number">
                <div className="icon">
                  <img
                    src={require("../images/icon/call-icon.png")}
                    alt="circle_right"
                    width="40px"
                  />
                </div>
                <h2>
                  +7959 5555 474 <br />
                  <span>
                    {" "}
                    Короткий номер:{" "}
                    <span className="text-primary">474</span>{" "}
                  </span>{" "}
                  <br />
                  <span className="text-muted">
                    <i>Звонить с 09:00 - 16:00</i>
                  </span>
                </h2>
              </div>
						</div>
          </div>
        </div>
      </section>

      <section id="about" className="about-area about-p pt-50 pb-50 p-relative ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 mt-5 pt-5 p-sm-none">
              <div className="about-content s-about-content">
                <div className="about-title second-atitle pb-40">  
                  <h5 style={{fontWeight: '500'}}>Ипотечное страхование</h5>
                  <h3>Преимущества страхования квартиры в ипотеке</h3>  
                  <ul className="mt-5">
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>стоимость страховки от 1000 рублей в год</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>подходит для страхования жилья в ипотеке</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>аккредитованная страхования компания</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>применимо только для страхования квартиры в ипотеке</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>оформление и оплата происходят онлайн удобным способом, в том числе и банковской картой</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 w-100 pl-5 pt-5 p-sm-none">
              <div className="s-about-img p-relative">
                <img className="images-radius" decoding="async" src={require('../images/inner_b3.jpg')} alt="img"/>        
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="emoji" className="mt-80 mb-80 m-sm-none">
        <div className="container rozsrochka">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title center-align mb-50 ">
                <h5 style={{fontWeight: '500'}}>ИПОТЕЧНОЕ СТРАХОВАНИЕ</h5> 
                <h4 className="mt-3 text-muted">Как дешевле застраховать квартиру при ипотеке</h4>
              </div>
              <hr className="mb-3" />
              <div className="row">
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">🧐</div>
                    <p>
                      <strong className="text-success">1. </strong> 
                      Когда человек заключает договор ипотеки, банк ставит обязательным условием страхование квартиры, то есть требуется обязательный договор страхования.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">🙃</div>
                    <p>
                      <strong className="text-success">2. </strong> 
                      Мы стараемся сделать полис дешевле, а сам процесс оформления договора – быстрым и менее затратным. 
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">😌</div>
                    <p>
                      <strong className="text-success">3. </strong> 
                      Сегодня застраховать квартиру, которая находится в ипотеке, можно прямо на сайте в режиме онлайн. 
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">😎</div>
                    <p>
                      <strong className="text-success">4. </strong> 
                      И расчет, и оформление страхового ипотечного полиса, и оплата проходят на сайте страховщика, что позволяет сэкономить и время, и деньги.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer/>
    </>
  );
};

export default Property;