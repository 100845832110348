import React from "react";
import { Helmet } from 'react-helmet';

import Header from "../components/Header";
import Title from "../components/Title";
import Footer from "../components/Footer";

const Health = (props) => {

  return (
    <>
      <Helmet>
        <title>Госавтополис - страховое агентство </title>
        <meta name="description" content="Медицинское страхование для вашего здоровья. Обеспечьте доступ к качественной медицинской помощи без больших затрат." />
      </Helmet>

      <Header />

      <Title title='Страхование от несчастных случаев' text='Доверие и защита в каждом полисе.'/>

      <section id="about" className="about-area about-p pt-50 pb-50 p-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 w-100 pr-5 pt-5 p-sm-none">
              <div className="s-about-img p-relative">
                <img className="images-radius" decoding="async" src={require('../images/top-view-stethoscope_23-2148021507.jpg')} alt="img"/>        
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 mt-5 pt-5 pl-5 p-sm-none">
              <div className="about-content s-about-content">
                <div className="about-title second-atitle pb-40">  
                  <h2>Страхование от несчастных случаев</h2>  
                  <p className="mt-5">
                    Страхование от несчастного случая и болезней (НС) — вид страхования, предназначенный для возмещения ущерба, вызванного потерей здоровья или смертью застрахованного. Существует масса причин, по которым может произойти несчастный случай, но все они неизбежно приведут к незапланированных расходам, «больничному» и временной нетрудоспособности.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contacts" className="make-call-area make-area p-relative">
        <div className="container">
          <div className="row pt-110 p-sm-none">
            <div className="col-lg-8 col-md-12">
              <div className="section-title cta-title"> 
                <h5 style={{fontWeight: '500'}}>ГосАвтоПолис</h5> 
                <h4 className="text-white" style={{fontSize: '30px'}}>Доступный тариф и выгодные условия!</h4>
                <p>Для получения индивидуального расчета свяжитесь с нашим менеджером.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 pt-3">
              <div className="call-number">
                <div className="icon">
                  <img
                    src={require("../images/icon/call-icon.png")}
                    alt="circle_right"
                    width="40px"
                  />
                </div>
                <h2>
                  +7959 5555 474 <br />
                  <span>
                    {" "}
                    Короткий номер:{" "}
                    <span className="text-primary">474</span>{" "}
                  </span>{" "}
                  <br />
                  <span className="text-muted">
                    <i>Звонить с 09:00 - 16:00</i>
                  </span>
                </h2>
              </div>
						</div>
          </div>
        </div>
      </section>

      <section id="about" className="about-area about-p pt-50 pb-50 p-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 mt-5 pt-5 pr-70 p-sm-none">
              <div className="about-content s-about-content">
                <div className="about-title second-atitle pb-40">  
                  <h3>Страхование физических лиц от несчастных случаев</h3>  
                  <p className="mt-5">
                    Добровольное страхование жизни и здоровья (как часто называют добровольное страхование от несчастных случаев и болезней) в этом случае – это хорошая гарантия того, что в сложной жизненной ситуации вам или вашим близким будет обеспечена финансовая стабильность на продолжительный период.
                    <br/>
                    Срок действия договора страхования от несчастных случаев – обычно 12 месяцев, но может быть изменен по желанию Страхователя.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 w-100 pl-5 pt-5 p-sm-none">
              <div className="s-about-img p-relative">
                <img className="images-radius" decoding="async" src={require('../images/unrecognizable-doctor-extending-digital-tab-anonymous-patient-fill-questionnaire_1098-19318.jpg')} alt="img"/>        
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="emoji" className="mt-80 mb-80 m-sm-none">
        <div className="container rozsrochka">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title center-align mb-50">
                <h5 style={{fontWeight: '500'}}>Страхование от несчастных случаев</h5> 
                <h4 className="mt-3 text-muted">Как оформить страховку НС</h4>
              </div>
              <hr className="mb-3" />
              <div className="row">
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">🤗</div>
                    <p>
                      <strong className="text-success">1. </strong> 
                      Мы подбираем оптимальные для вас тарифы с учётом всех нюансов вашего конкретного случая.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">🙃</div>
                    <p>
                      <strong className="text-success">2. </strong> 
                      Вы выбираете понравившуюся вам страховую компанию. Работаем с более, чем 15 компаниями.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">🤓</div>
                    <p>
                      <strong className="text-success">3. </strong> 
                      Оформление полиса происходит в офисе страховой компании, что гарантирует вам прозрачность сделки. 
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">😏</div>
                    <p>
                      <strong className="text-success">4. </strong> 
                      После страхования мы оказываем бесплатные консультации при страховом случае.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about-area about-p pb-50 p-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 mt-5 pt-5 pr-5 p-sm-none">
              <div className="about-content s-about-content">
                <div className="about-title second-atitle pb-40">  
                  <h5 style={{fontWeight: '500'}}>Cтрахование сотрудников от несчастных случаев</h5>
                  <h4>Страхование сотрудников – инструмент повышения лояльности персонала.</h4>  
                  <p className="mt-4">
                    Платежи по страхованию сотрудников можно включить в расходы компании для оптимизации налоговой нагрузки.
                    Страховой тариф по договору страхования от несчастного случая для работников компании зависит от:
                  </p>
                  <ul className="mt-3">
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>выбранной комбинации страховых рисков</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>времени действия</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>средней страховой суммы</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>срока действия договора</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>квалификации сотрудника</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 pl-5 pt-5 w-100 p-sm-none">
              <div className="s-about-img p-relative">
                <img className="images-radius" decoding="async" src={require('../images/small-business-departments-success.png')} alt="img"/>     
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer/>
    </>
  );
};

export default Health;