import superagentPromise from "superagent-promise";
import _superagent from "superagent";

const superagent = superagentPromise(_superagent, global.Promise);
const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;
const BOT_URL = `https://api.telegram.org/bot6534007411:AAGnYoc166AjH30qr5gxM0mFc_jxS559l-Q
/sendMessage?chat_id=-1001827112050&text=`;

const handleErrors = (err) => {
  if (err && err.response && err.response.status === 401) {
    console.log(err);
  }
  return err;
};

const requests = {
  botSend: async (message) =>
    await superagent
      .get(`${BOT_URL}${encodeURI(message)}&parse_mode=html`)
      .end(handleErrors),
  get: async (url, query) =>
    await superagent
      .get(`${API_URL}${url}`)
      .query(query)
      .end(handleErrors),
  post: (url, body) =>
    superagent
      .post(`${API_URL}${url}`, body)
      .end(handleErrors),
  // put: (url, body) =>
  //   superagent.put(`${API_URL}${url}`, body).use(tokenPlugin).end(handleErrors),
  // patch: (url, body, root = API_URL) =>
  //   superagent.patch(`${root}${url}`, body).use(tokenPlugin).end(handleErrors),
};

export const Api = {
  telegramSend: async (message) => await requests.botSend(message),
};