import React from "react";
import { Helmet } from 'react-helmet';

import Header from "../../components/Header";
import Title from "../../components/Title";
import Footer from "../../components/Footer";

const WhatToDo = (props) => {
  return (
    <>
      <Helmet>
        <title>Что делать при ДТП по КАСКО и ОСАГО: инструкция и действия - Госавтополис</title>
        <meta name="description" content="Основной порядок действий, если Вы попали в ДТП и у Вас есть полис страхования КАСКО или ОСАГО. Инструкция по шагам, что нужно делать при аварии." />
      </Helmet>

      <Header />

      <Title
        title='Что делать при ДТП по КАСКО и ОСАГО: инструкция и действия'
        text='Доверие и защита в каждом полисе.'
        crumb='Информация'
      />

      <section id="about" className="about-area about-p pt-50 pb-50 p-relative">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="info-page-text">
                <h4 className="text-center mb-4">
                  Что делать при ДТП по КАСКО или ОСАГО? 🤔
                </h4>
                <p>Мы крайне рекомендуем Вам распечатать эту статью и положить себе в бардачок, будет прекрасно, если Вам она НЕ пригодиться, но при ДТП Вы сохраните себе кучу нервов. Если произойдет страховой случай по ОСАГО или страховой случай по КАСКО, Вы будете значть, что надо делать!</p>
                <p>Ситуация на дорогах порой бывает не совсем, скажем так, стабильной, и, зачастую, мы всё делаем правильно и соблюдаем все правила, но по какой-то причине другие автолюбители иногда любят их игнорировать. Часто повреждения авто при аварии не фатальные, но 400 000 руб., которые Вы максимум можете получить при возмещении по ОСАГО, не всегда могут покрыть ущерб от ДТП (особенно если у Вас дорогая машина). При страховании КАСКО положено возмещение 100% ущерба (даже если это полная стоимость автомобиля, который стоит 5 000 000 рублей).</p>
                <p>Чтобы быть уверенным при ДТП, купить полис автострахования с <strong>экономией до 50%</strong>, воспользуйтесь калькуляторами. Расчет обязательного автострахования производится на калькуляторе ОСАГО. Кроме того, Вы можете сделать расчет добровольного автострахования на калькуляторе КАСКО, сравнить стоимость полиса в 10-30 страховых компаниях Вашего города.</p>
                <h5>✅ Последовательность действий при ДТП, если у Вас КАСКО и ОСАГО</h5>
                <p>Итак, при что надо делать при аварии, если у Вас полис ОСАГО и/или КАСКО:</p>
                <p>
                </p><h5>1. Если у Вас при ДТП есть только ОСАГО и Вы виноваты:</h5>
                <p>- Возмещения по ремонту Вашей машины НЕ будет,</p>
                <p>- Возмещение на ремонт пострадавшей машины – 400 000 руб.</p>
                <p>
                </p><h5>2. Если у Вас при ДТП есть только ОСАГО и Вы НЕ виноваты:</h5>
                <p>- Вы получите возмещение до 400 000 руб. на ремонт Вашего авто,</p>
                <p>- НО!!! если у того, кто Вас “подбил” нет ОСАГО, Вам придется с ним судиться или ремонтировать свой авто за свой счёт,</p>
                <p>- При любом раскладе виновник будет ремонтировать свой авто сам.</p>
                <p>
                </p><h5>3. Если у Вас при ДТП есть полис КАСКО и вы виноваты:</h5>
                <p>- Обратитесь в свою компанию с полисом КАСКО и Вы получите полное возмещение (на ремонт Вашего авто),</p>
                <p>- Пострадавший получит возмещение до 400 000 руб. по Вашему полису ОСАГО (пункт 1) или по его полису КАСКО (если он у него есть)</p>
                <p>
                </p><h5>4. Если у Вас при ДТП есть полис КАСКО и вы НЕ виноваты:</h5>
                <p>- Вы можете обратиться за возмещением на ремонт Вашего автомобиля по полису КАСКО или ОСАГО. Но мы рекомендуем обращаться по КАСКО, так как по ОСАГО Вам дадут возмещение с учетом износа, а по КАСКО – без износа деталей ТС (уточните наличие этого пункта в Вашем полисе КАСКО),</p>
                <p>- Пострадавший получит возмещение до 400 000 руб. по Вашему полису ОСАГО (пункт 1) или по его полису КАСКО (если он у него есть).</p>
                <h5>Последовательность действий при ДТП:</h5>
                <p><strong>1.</strong> Попали в аварию – включите аварийку. Сразу после того, как Вы включили аварийку, постарайтесь выдохнуть и взять себя в руки. Не теряйте контроль.</p>
                <p><strong>2.</strong> Установите аварийный знак. 15 метров от машины – в городе, не менее 30 – на трассе. Если вдруг у Вас его нет аварийного знака – СРОЧНО купите. Это необходимо, по тому, как машины могут не заметить Ваше авто на дороге (тем более, если это ночь) и “врезаться” в Ваш авто еще раз.</p>
                <p><strong>3.</strong> Не конфликтуйте с другими участниками ДТП. Это не приведет к тому, что страховая Вам выплатит потом больше денег. Берегите свои нервы и действуйте по инструкции.</p>
                <p><strong>4.</strong> Вызовите “скорую помощь”, если при аварии есть пострадавшие.</p>
                <p><strong>5.</strong> Вызовите ГИБДД (если нет возможности оформить ДТП по Европротоколу). Не слушайте никого, обязательно вызывайте работников ГИБДД для того, чтобы они зафиксировали сам факт ДТП + все повреждения. Помните, что в состоянии стресса и аффекта, Вам будет очень сложно самостоятельно оценить ситуацию!</p>
                <p><strong>6.</strong> Не трогайте авто с места до приезда ГИБДД. В правилах дорожного движения прописаны Ваши обязанности при ДТП, а именно: остановить транспортное средство, включить “аварийку”, выставить аварийны знак.</p>
                <p><strong>7.</strong> Сделайте пару фото происшествия вместо разборок с другими участниками ДТП. Их можно будет приложить к протоколу.</p>
                <p><strong>8.</strong> Когда приедет ГИБДД не будьте пассивными. Не оставляйте работников ГИБДД (инспектора) с другими участниками ДТП на едине. Вы можете не стесняться подсказать инспектору заглянуть под крылья и капот, дабы зарегистрировать ВСЕ повреждения. Это важно, по тому как возмещение будет производиться исходя из того, что инспектор укажет в своём протоколе.</p>
                <p><strong>9.</strong> Проверьте, чтобы в протоколе были зафиксированы ВСЕ повреждения. Так же рекомендуем попросить инспектора указать в протоколе все возможные СКРЫТЫЕ повреждения.</p>
                <p><strong>10.</strong> Запишите данные остальных участников ДТП. Это может быть ФИО, адрес, где работает и т.д.</p>
                <p>На месте никаких справок Вам не выдадут.</p>
                <p>Теперь (после осмотра и составления протокола) Вы можете отогнать свой авто.</p>
                <h5>Действия после ДТП:</h5>
                <p><strong>11.</strong> Следующий этап – Вам нужно забрать протокол в отделении ГИБДД. Уточните адрес у инспектора, который будет составлять Вам протокол.</p>
                <p><strong>12.</strong> И последний этап – обратитесь в страховую компанию за возмещением (выплатой по ОСАГО или выплатой по КАСКО). Пройдя по ссылкам, Вы узнаете перечень документов для получения страховых возмещениий.</p>
                <h5>Подведем итог, что нужно делать:</h5>
                <p>1. В момент аварии на дороге Вы не нервничаете и не браните себя и всех окружающих, ведь Вы на уверенны, что Вам возместят деньги за ущерб от этого “недоразумения”.</p>
                <p>2. Если получилось серьезным, а Вы на дорогой машине, то Вы получите полное возмещение на ремонт Вашего авто. То есть если Вы за рулем BMW X6 и так вышло, что “снесло полморды”, то Вам страховая выплатит столько денег, сколько нужно на ВЕСЬ ремонт, а не максимум 400 000 руб., которые Вам выплатили бы по ОСАГО. В случае того, если у Вас есть КАСКО.</p>
                <p>3. Если Вы попали в ДТП и Вы ВИНОВАТЫ – Вам страховая выплатит так же все необходимые деньги на ремонт Вашего авто. Если у Вас есть полис КАСКО.</p>
                <p>4. Если Вы попали в ДТП и НЕ виноваты, и ущерб не такой и большой, Вам выплатят деньги на ремонт БЕЗ УЧЁТА ИЗНОСА. Если у Вас есть КАСКО.</p>
                <p>5. Если Вы попали в ДТП и не виноваты, а у виновника нет ОСАГО, Вам НЕ нужно будет с ним судиться, обращаться к автоюристу, чтобы “выбить” денег на ремонт своего авто. Ремонт Вашего оплатит страховая, в полном объёме и без износа. Если у Вас есть КАСКО.</p>
                <p>6. Вы станете спокойно спать по ночам, даже если не поставили машину на стоянку (в гараж). Если ДАЖЕ Вашу машину угонят – страховая выплатит Вам 100% стоимость. Если у Вас есть КАСКО.</p>
                <p>7. Если какой-то “шутник” поцарапал Вам дверь или стекло (или и то и другое), что страховая Вам выплатит деньги на покупку нового стекла и покраску двери, веди КАСКО защищает от таких случаев, как “шаловливые” соседи, упавшие деревья, град, пожары, наводнения.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default WhatToDo;