import React from "react";
import { Helmet } from 'react-helmet';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Vacancy from "../components/Vacancy";

const Contacts = (props) => {
  
  return (
    <>
      <Helmet>
        <title>Контакты - ГосАвтоПолис страховая компания </title>
        <meta name="description" content="Страхование имущества от непредвиденных рисков. Охраните свое имущество от пожаров, воровства и других опасностей." />
      </Helmet>

      <Header />

      <section 
        className="breadcrumb-area d-flex align-items-center" 
        style={{backgroundImage: `url(${require('../images/bg/header-bg.png')})`, minHeight: '400px', }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  <h3 className="mb-4">Контакты</h3>
                  <p style={{color: '#08C802'}} >Доверие и защита в каждом полисе.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 text-right">
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Главная</a></li>
                    <li className="breadcrumb-item active">Контакты</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about-area about-p pt-50 pb-20 p-relative">
        <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                <img
                  src={require("../images/contacts2.png")}
                  className="media-sm-img"
                  alt=""
                />
              </div>
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 pl-100 p-sm-none">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="section-title center-align mb-3 ">
                            <h5>
                                <span className="line2">
                                    {" "}<img src={require("../images/bg/circle_right.png")} alt="circle_right"/>
                                </span>
                                {" "}Свяжитесь с нами{" "}
                                <span className="line3">
                                    {" "}<img src={require("../images/bg/circle_left.png")}alt="circle_left"/>
                                </span>
                            </h5>
                            <h4>Мы готовы ответить на ваши вопросы и помочь вам получить надежную защиту для вашего автомобиля. Обратитесь к нам сегодня!</h4>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row align-items-center mt-5">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 pl-4">
                        <div className="about-content s-about-content">
                        <ul className="ab-ul">
                            <li className="mb-5 w-100">
                                <div className="icon-box mr-4">
                                    <div className="icon my-auto">☎️</div>
                                </div>
                            <div className="text my-auto">
                                + 7959 5555 474 <br/>
                                Короткий номер: 474 <br/>
                                Звонить с 09:00 - 16:00
                            </div>
                            </li>
                            <li className="mb-5 w-100">
                                <div className="icon-box mr-4">
                                    <div className="icon my-auto">📩</div>
                                </div>
                            <div className="text my-auto">
                              lugansk@gosavtopolis.ru
                            </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 pl-4">
                        <div className="about-content s-about-content m-sm-none">
                        <ul className="ab-ul">
                            <li className="mb-5 w-100">
                                <div className="icon-box  mr-4">
                                    <div className="icon my-auto">📍</div>
                                </div>
                            <div className="text my-auto">
                                г. Луганск, <br/>
                                улица Ленина 236/30
                            </div>
                            </li>
                            <li className="mb-5 w-100">
                                <div className="icon-box mr-4">
                                    <div className="icon my-auto">⏰</div>
                                </div>
                            <div className="text my-auto">
                                пн-пт 09:00 - 16:00 <br/>
                                сб 09:00 - 14:00 <br/>
                                вс выходной
                            </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>  
      </section>

      <Vacancy/>

      <Footer/>
    </>
  );
};

export default Contacts;