import React from "react";
import { Helmet } from 'react-helmet';

import Header from "../components/Header";
import Title from "../components/Title";
import Footer from "../components/Footer";

const Tourism = (props) => {

  return (
    <>
      <Helmet>
        <title>Туристическое страхование - ГосАвтоПолис страховая компания </title>
        <meta name="description" content="Страхование при путешествиях. Защита от непредвиденных ситуаций во время путешествий и отдыха." />
      </Helmet>

      <Header />

      <Title title='Страхование для туристов' text='Доверие и защита в каждом полисе.'/>

      <section id="about" className="about-area about-p pb-50 p-relative ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 mt-5 pt-5 pr-5 p-sm-none">
              <div className="about-content s-about-content">
                <div className="about-title second-atitle pb-40">  
                  <h5 style={{fontWeight: '500'}}>Полис медицинского страхования для туристов</h5>
                  <h4>Страхование выезжающих за рубеж – это способ финансово защитить туриста в путешествиях.</h4>  
                  <p className="mt-4">
                    Купить туристическую страховку можно онлайн – теперь это про просто и занимает всего 10 минут. Вы можете рассчитать стоимость и оформить онлайн прямо на сайте по оптимальной для Вас цене. 
                    <br/>
                    Страховка для туриста поможет в другой стране, т.к. именно такой полис защищает путешественника за границей!
                  </p>                  
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 pl-5 pt-5 w-100 p-sm-none">
              <div className="s-about-img p-relative">
                <img className="images-radius" decoding="async" src={require('../images/top-view-tourist-objects-frame-with-copy-space_23-2148786099.jpg')} alt="img"/>     
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contacts" className="make-call-area make-area p-relative">
        <div className="container">
          <div className="row pt-110 p-sm-none">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="section-title cta-title"> 
                <h5 style={{fontWeight: '500'}}>ГосАвтоПолис</h5> 
                <h4 className="text-white" style={{fontSize: '30px'}}>Доступный тариф и выгодные условия!</h4>
                <p>Для получения индивидуального расчета свяжитесь с нашим менеджером.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 pt-3 col-12">
              <div className="call-number">
                <div className="icon icon-phone-bg">
                  <img
                    src={require("../images/icon/call-icon.png")}
                    alt="circle_right"
                    width="40px"
                    className="icon-phone"
                  />
                </div>
                <h2>
                  +7959 5555 474 <br />
                  <span>
                    {" "}
                    Короткий номер:{" "}
                    <span className="text-primary">474</span>{" "}
                  </span>{" "}
                  <br />
                  <span className="text-muted">
                    <i>Звонить с 09:00 - 16:00</i>
                  </span>
                </h2>
              </div>
						</div>
          </div>
        </div>
      </section>

      <section id="about" className="about-area about-p mt-5 pb-50 p-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 pr-5 pt-5 w-100 p-sm-none">
              <div className="s-about-img p-relative">
                <img className="images-radius" decoding="async" src={require('../images/beautiful-tourist-woman-summer-hat-wearing-red-sunglasses-holding-travel-suitcase-passport-with-tickets-with-smile-face-happy-positive-raising-fist-after-victo_141793-22075.jpg')} alt="img"/>     
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 mt-5 pt-5 pl-5 p-sm-none">
              <div className="about-content s-about-content">
                <div className="about-title second-atitle pb-40">  
                  <h5 style={{fontWeight: '500'}}>Страхование для туристов</h5>
                  <h3>От чего защищает полис для туристов?</h3>  
                  <ul className="mt-5">
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>от несчастного случая за границей</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>от невыезда</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>от задержки рейса</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>от повреждения (утраты) багажа</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="emoji" className="mt-80 m-sm-none">
        <div className="container rozsrochka">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title center-align mb-50">
                <h5 style={{fontWeight: '500'}}>Покупка онлайн</h5> 
                <h4 className="mt-3 text-muted">Покупка туристической страховки онлайн имеет массу преимуществ</h4>
              </div>
              <hr className="mb-3" />
              <div className="row">
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">😍</div>
                    <p>
                      <strong className="text-success">1. </strong> 
                      Вы экономите свое время. Процесс оформления занимает считанные минуты.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">😌</div>
                    <p>
                      <strong className="text-success">2. </strong> 
                      Застраховать себя перед выездом за границу можно в любое удобное время.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">🧐</div>
                    <p>
                      <strong className="text-success">3. </strong> 
                      Вам больше не нужно ездить по офисам, сравнивать цены и рассчитывать наиболее выгодный пакет.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">😊</div>
                    <p>
                      <strong className="text-success">4. </strong> 
                      Документы вы получите на электронный адрес сразу после оформления и оплаты.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="cubes"  className="testimonial-area2 pt-20 pb-100 p-sm-none" style={{backgroundColor: '#ffff'}}>
        <div className="container">
          <div className="upper-box mt-100">
            <div className="single-item-carousel owl-carousel owl-theme text-center">
              <figure className="image">
                <img className="images-radius" src={require('../images/full-shot-happy-couple-traveling_23-2149272137.jpg')} alt=""/>
              </figure>  
            </div>
          </div>
        </div>
      </section>

      <Footer/>
    </>
  );
};

export default Tourism;