import React from "react";
import { Helmet } from 'react-helmet';

import Header from "../components/Header";
import Title from "../components/Title";
import Footer from "../components/Footer";

const Property = (props) => {
  
  return (
    <>
      <Helmet>
        <title>Госавтополис - страховое агентство </title>
        <meta name="description" content="Страхование имущества от непредвиденных рисков. Охраните свое имущество от пожаров, воровства и других опасностей." />
      </Helmet>

      <Header />

      <Title title='Страхование имущества' text='Доверие и защита в каждом полисе.'/>

      <section id="services" className="services-area pt-20 pb-20 fix p-sm-none">
        <div className="container">
          <div className="row align-items-center my-5">
            <div className="col-lg-12 col-md-12">
              <div className="about-content s-about-content">
                <div className="about-title second-atitle pb-20">   
                  <h5 style={{fontWeight: '500'}}>Страхование имущества и жилья</h5>
                  <h3>Причины оформления страховки жилья в ГосАвтоПолис ✅</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-5 pt-5">
            <div className="col-lg-6 col-md-12">
              <ul className="services-bd-box">
                <li>
                  <div className="services-bd-01">
                    <div className="services-icon" style={{background: '#bfeec3'}}>
                      <img decoding="async" src={require('../images/icon/iconnn4.png')} alt="img"/>
                    </div>
                    <div className="services-icon2">
                      <img decoding="async" src={require('../images/icon/se-icon7-af.png')} alt="img"/>
                    </div>
                    <div className="services-content2">
                      <h5>Надежно</h5>   
                      <p>Большое внимание к удобству при наступлении страхового случая.</p>
                    </div>
                  </div> 
                </li>
                <li>
                  <div className="services-bd-01">
                    <div className="services-icon" style={{background: '#bfeec3'}}>
                      <img decoding="async" src={require('../images/icon/iconnn1.png')} alt="img"/>
                    </div>
                    <div className="services-icon2">
                      <img decoding="async" src={require('../images/icon/se-icon4-af.png')} alt="img"/>
                    </div>
                    <div className="services-content2">
                      <h5>Быстро</h5>   
                      <p>Для покупки полиса не требуется тратить время на посещение офиса. </p>
                    </div>
                  </div> 
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-12">
              <ul className="services-bd-box">
                <li>
                  <div className="services-bd-01">
                    <div className="services-icon" style={{background: '#bfeec3'}}>
                      <img decoding="async" src={require('../images/icon/iconnn3.png')} alt="img"/>
                    </div>
                    <div className="services-icon2">
                      <img decoding="async" src={require('../images/icon/se-icon6-af.png')} alt="img"/>
                    </div>
                    <div className="services-content2">
                      <h5>Подход</h5>   
                      <p>Консультация и помощь будет оказана в любое время.</p>
                    </div>
                  </div> 
                </li>
                <li>
                  <div className="services-bd-01">
                    <div className="services-icon" style={{background: '#bfeec3'}}>
                      <img decoding="async" src={require('../images/icon/iconnn2.png')} alt="img"/>
                    </div>
                    <div className="services-icon2">
                      <img decoding="async" src={require('../images/icon/se-icon5-af.png')} alt="img"/>
                    </div>
                    <div className="services-content2">
                      <h5>Качество</h5>   
                      <p>Компания уделяет большое внимание качеству обслуживания.</p>
                    </div>
                  </div> 
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about-area about-p pt-50 pb-50 p-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 mt-5 pt-5">
              <div className="about-content s-about-content">
                <div className="about-title second-atitle pb-40">  
                  <h5 style={{fontWeight: '500'}}>Страхование дома</h5>
                  <h3>Страхование имущества осуществляется от таких рисков: </h3>  
                  <ul className="mt-5">
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>кража движимого имущества</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>пожар</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>стихийные бедствия</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>проникновение воды из соседних (чужих) помещений</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>аварии инженерных систем</li>
                    <li><i className="fas fa-chevron-right mt-1 mr-2" style={{color: '#08C802'}}></i>и другое</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 pl-5 pt-5 w-100 p-sm-none">
              <div className="s-about-img p-relative">
                <img className="images-radius" decoding="async" src={require('../images/architecture-small-residential-cottage-surrounded-by-nature-generative-ai_188544-7711.jpg')} alt="img"/>     
              </div>
            </div>
          </div>
        </div>
		  </section>

      <section id="contacts" className="make-call-area make-area p-relative ">
        <div className="container">
          <div className="row pt-110 p-sm-none">
            <div className="col-lg-8 col-md-12">
              <div className="section-title cta-title"> 
                <h5 style={{fontWeight: '500'}}>ГосАвтоПолис</h5> 
                <h4 className="text-white" style={{fontSize: '30px'}}>Доступный тариф и выгодные условия!</h4>
                <p>Для получения индивидуального расчета свяжитесь с нашим менеджером.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 pt-3">
              <div className="call-number">
                <div className="icon">
                  <img
                    src={require("../images/icon/call-icon.png")}
                    alt="circle_right"
                    width="40px"
                  />
                </div>
                <h2>
                  +7959 5555 474 <br />
                  <span>
                    {" "}
                    Короткий номер:{" "}
                    <span className="text-primary">474</span>{" "}
                  </span>{" "}
                  <br />
                  <span className="text-muted">
                    <i>Звонить с 09:00 - 16:00</i>
                  </span>
                </h2>
              </div>
						</div>
          </div>
        </div>
      </section>

      <section id="emoji" className="mt-120 m-sm-none">
        <div className="container rozsrochka">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title center-align mb-50">
                <h5 style={{fontWeight: '500'}}>Особенности покупки страховки </h5> 
                <h3>Страхование квартиры</h3>
                <h4 className="mt-4 text-muted">При оформлении страхования квартиры стоит учесть несколько нюансов:</h4>
              </div>
              <hr className="mb-3" />
              <div className="row">
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">🧐</div>
                    <p>
                      <strong className="text-success">1. </strong> 
                      Если стоимость квартиры более 5 000 000 рублей, то проводится осмотр и опись имущества, подлежащего страхованию.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">😯</div>
                    <p>
                      <strong className="text-success">2. </strong> 
                      Квартира, где были незаконные перепланировки, проведена проводка по другому плану или совершены прочие действия: страховка будет стоить несколько дороже.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">😌</div>
                    <p>
                      <strong className="text-success">3. </strong> 
                      Если приобретена квартира вторичного типа, то страхуется она только по инициативе собственника и требует независимой оценки общего состояния дома, а также коммуникаций.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-box">
                    <div className="icon">😬</div>
                    <p>
                      <strong className="text-success">4. </strong> 
                      Если состояние дома или систем, его обеспечивающих, плохое, то страхования компания имеет право отказать в страховке.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="img" className="testimonial-area2 pt-40 pb-100 p-sm-none" style={{backgroundColor: '#ffff'}}>
        <div className="container">
          <div className="upper-box mt-100">
            <div className="single-item-carousel owl-carousel owl-theme">
              <figure className="image">
                <img className="images-radius" src={require('../images/protfolio-dt-img.png')} alt=""/>
              </figure>  
            </div>
          </div>
        </div>
      </section>

      <Footer/>
    </>
  );
};

export default Property;