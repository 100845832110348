import React from "react";
import { Api } from "../helpers/api";
import { sendNotification } from "../helpers/notification";
// import { isEmpty } from "lodash";

export const PageContext = React.createContext({});

export class PageProvider extends React.Component {
  state = {
    updateState: async (key, val) => {
      await this.setState((state) => {
        state[key] = val;
        return state;
      })
    },

    clean: async () => {
      return this.setState({});
    },

    owner_eql_driver: async () => {
      this.setState({owner_lastname: this.state.driver_lastname})
      this.setState({owner_name: this.state.driver_name})
      this.setState({owner_thridname: this.state.driver_thridname})
      this.setState({owner_birthday: this.state.driver_birthday})
    },

    clear_owner_eql_driver: async () => {
      this.setState({owner_lastname: null})
      this.setState({owner_name: null})
      this.setState({owner_thridname: null})
      this.setState({owner_birthday: null})
    },

    peshOrder: async () => {
      let message = "";
      message += '<b>Новый заказ страховки</b> ✅\n';
      message += '-----------------------\n';
      message += '<b>Шаг 1: данные автомобиля</b>\n';
      message += `Категория ТС: ${this.state.vehicleCategory}\n`;
      message += `Гос. Номер: ${this.state.carNumber}\n`;
      message += `Марка: ${this.state.brand}\n`;
      message += `Модель: ${this.state.model}\n`;
      message += `Год выпуска: ${this.state.year}\n`;
      message += `Мощность: ${this.state.power}\n`;
      message += `Вин номер: ${this.state.vin}\n`;
      message += `Тип документа: ${this.state.document}\n`;
      message += `Номер документа: ${this.state.pts}\n`;
      message += `Дата выдачи документа: ${this.state.documentObtainingDate}\n`;
      message += '-----------------------\n';
      message += '<b>Шаг 2: данные водителя</b>\n';
      message += `Фамилия: ${this.state.driver_lastname}\n`;
      message += `Имя: ${this.state.driver_name}\n`;
      message += `Отчество: ${this.state.driver_thridname}\n`;
      message += `Дата розждения: ${this.state.driver_birthday}\n`;
      message += `Серия и номер ВУ: ${this.state.driver_license}\n`;
      message += `Дата выдачи прав: ${this.state.driver_first_license_date}\n`;
      message += `Дата начала стажа: ${this.state.driver_first_exp}\n`;
      message += '-----------------------\n';
      message += '<b>Шаг 3: данные владельца</b>\n';
      message += `Фамилия: ${this.state.owner_lastname}\n`;
      message += `Имя: ${this.state.owner_name}\n`;
      message += `Отчество: ${this.state.owner_thridname}\n`;
      message += `Дата роздения: ${this.state.owner_birthday}\n`;
      message += `Серия и номер паспорта: ${this.state.owner_passport}\n`;
      message += `Дата выдачи паспорта: ${this.state.owner_passport_date}\n`;
      message += `Адрес регистрации: ${this.state.owner_addres}\n`;
      message += `Квартира: ${this.state.owner_house_number}\n`;
      message += `👉 Почта: <b>${this.state.owner_email}</b>\n`;
      message += `👉 Номер телефона: <b>${this.state.owner_phone}</b>\n`;
      message += '-----------------------\n';

      await Api.telegramSend(message)
        .then((res) => {
          sendNotification("success", "Заявка успешно отправлена", "Ожидайте звонка от нашего менеджера в течении 30-60 минут.");
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification("danger", "Что то пошло не так.", "Запрос не отправлен, свяжитесь с нами по номеру телефона.");
        });
    },

    vehicleCategory: "B — автомобиль"
  };

  render() {
    return (
      <PageContext.Provider value={this.state}>
        {this.props.children}
      </PageContext.Provider>
    );
  }
}
