import React from "react";
import { Helmet } from 'react-helmet';

import Header from "../../components/Header";
import Title from "../../components/Title";
import Footer from "../../components/Footer";

const Page = (props) => {
  return (
    <>
      <Helmet>
        <title>Правила ОСАГО и закон об ОСАГО. Основные условия и изменения - Госавтополис</title>
        <meta name="description" content="Закон (ФЗ) и правила о страховании ОСАГО - все условия автострахования. Изменения, актуальные в 2019 году. Скачать или читать условия онлайн." />
      </Helmet>

      <Header />

      <Title
        title='Правила ОСАГО и закон об ОСАГО. Основные условия и изменения'
        text='Доверие и защита в каждом полисе.'
        crumb='Информация'
      />

      <section id="about" className="about-area about-p pt-50 pb-50 p-relative">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className="text-center mb-4">Закон об ОСАГО и правила ОСАГО</h4>
              <div className="info-page-text">
                <p>Как “работает” тот или иной полис определяется в <a href="https://gosavtopolis.ru/pravila/">правилах страхования</a>. </p>
                <p>Правила страхования ОСАГО – это документ, который регулирует аспекты отношений между страховыми компаниями и страхователями при заключении договора ОСАГО.</p>
                <p>Договором страхования ОСАГО является Ваш полис, который содержит информацию об автомобиле, водителях, а также краткие условия страхования.</p>
                <p> Полные условия и правила обязательного страхования автогражданской ответственности регламентируется Постановлением Правительства РФ от 07 мая 2003 года №263, который назвается “Об утверждении Правил обязательного страхования гражданской ответственности владельцев транспортных средств”. <strong>! Скачать подробные </strong> <a href="/Pravila-kasko-osago/pravila-osago.pdf">условия и правила автострахования ОСАГО</a>.</p>
                <p>Кроме того, существует закон об ОСАГО, которые также регламентирует отношения страховой компании и клиента. Данный закон назвается Федеральный закон Российской Федерации N 40 от 25 апреля 2002 года «Об обязательном страховании гражданской ответственности владельцев транспортных средств» (ФЗ №40 об ОСАГО). <strong>! Скачать полный текст </strong> <a href="/Pravila-kasko-osago/zakon-ob-osago.pdf">закона об ОСАГО</a>.</p>
                
                <h4 className="text-center my-4">Изменения в законе по ОСАГО</h4>
                <p>Какие произошли изменения в законе при страховании ОСАГО? С 2014 года произошел ряд изменений в правилах автострахования. Вносились коррективы и в 2015 году. Власти стараются приблизить страхование к европейскому уровню, и поэтому проводятся реформы.</p>
                <p>
                  Рассмотрим утвержденные новые изменения в ОСАГО с 1 июля 2015 года подробнее:
                </p>
                <ul>
                  <li>Владельцы транспортных средств теперь могут не вызывать инспектора в случае ДТП, если размер причинённого ущерба менее 50 000 руб.Водитель вносит убытки в европротокол, который в дальнейшем послужит основанием для страхового возмещения.</li>
                  <li>Страховщик не вправе отказать в продаже ОСАГО иначе ему грозит штраф– 50 000 руб. Полис можно приобрести в любом офисе. Эта мера пресекает отказы страховщиков от реализации ОСАГО, особенно в убыточных регионах.</li>
                  <li>Возможности страхователей расширены. Они могут получить страховую выплату, как в денежном эквиваленте, таки в натуральном (ремонт авто).</li>
                  <li>Утверждён порядок досудебного рассмотрения спора. Оспаривая сумму выплаты, водитель просто дважды направляет претензию в СК.</li>
                  <li>Имея справку ОСАГО, можно получить компенсацию по ДСАГО и каско.</li>
                  <li>При занесении убытков в европротокол, страховые компании не вправе настаивать на дополнительных подтверждающих документах от автовладельца, кроме составленных при аварии.</li>
                  <li>Размер выплат определяется в соответствии со страховым полисом, но не лимитом по европротоколу.</li>
                  <li>В нескольких регионах, куда входят: Москва и МО, Санкт-Петербург и Ленинградская область, максимальная выплата может достигать 400 000 руб. В остальных территориальных округах сумма возмещения приблизится к этой отметке в 2019 году.</li>
                  <li>При задержке платежа страховщику придётся оплатить 0,05 – 50% с учётом ситуации. Размер санкции не может превышать страховую сумму.</li>
                  <li>Тарифы возросли на 40 – 60%.</li>
                  <li>Возмещение убытков производится только своей страховой фирме (в случае ДТП, попадающих в правовое поле ПВУ).</li>
                  <li>При составлении европротокола водитель обязан в течение 5 дней проинформировать страховую компанию о факте ДТП.</li>
                  <li>Новое требование к банкам – РСА вправе размещать свободный капитал фонда. Теперь банк не подконтролен страховщикам.</li>
                  <li>Внедрены правила исключения страховщиков из списков РСА, отзывов страховой лицензии.</li>
                  <li>Водится понятие «тарифного коридора» ОСАГО (допустимые границы тарифов). Страховщики формируют тарифы только в этих пределах.</li>
                  <li>Гусеничная (санная) техника не подлежит страхованию.</li>
                  <li>Приостановлено страхование ОСАГО на прицепы по некоторым контрактам. Теперь делается отметка в полисах тягачей за дополнительную плату.</li>
                  <li>Все данные о заключённых контрактах обязательно вносятся в АИС РСА в течение дня, следующего за подписанием договора.</li>
                  <li>При оформлении договора нужно пользоваться сведениями АИС РСА о показателях <a href="https://gosavtopolis.ru/proverka-kbm/">КБМ ОСАГО</a> и о техосмотре.</li>
                  <li>За незаконное использование бланков страховки страховщики несут ответственность.</li>
                  <li>Оплата производится страховыми компаниями по всем полисам, кроме похищенных.</li>
                  <li>Введена государственная аттестация экспертов-техников.</li>
                </ul>
                <strong>Установлен ещё ряд правил: </strong>
                <ul>
                  <li>Страховые компании могут давать клиентам скидки, но вправе и увеличивать стоимость страховки.</li>
                  <li>Учреждена единая методика оценки причинённого ущерба.</li>
                  <li>Внедрена база данных, где присутствуют страховые истории всех автовладельцев.</li>
                </ul>
                Последние нововведения в правила ОСАГО начали действовать с июля 2015г. и относятся к правилам оформления электронных полисов. Оформить <a href="https://gosavtopolis.ru/osago/elektronnoe-online/">электронное ОСАГО</a>.
                Обратитесь к Вашему страховому брокеру за более подробной консультацией. Профессионалы страхового рынка хорошо знают условия страхования ОСАГО и могут помочь Вам избежать проблем при автостраховании. Рассчитать ОСАГО онлайн и заказать оформление полиса можно <a href="https://gosavtopolis.ru/kalkulyator-osago/">на этой странице</a> сайта страхового брокера “Госавтополис”. </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Page;